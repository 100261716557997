import './VariableDetailsView.scss'
import TopSection from '../TopSection/TopSection'
import { observer } from 'mobx-react'
import Spinner from '../Spinner/Spinner'
import FormSectionView from './FormSectionView/FormSectionView'


type VariableDetailsViewProps = {
  selectedVariableId: number
  selectedVariableDetails: VariableDetail | undefined
  clearSelectedVariable: () => void
  isLoadingVariableDetails?: boolean
  detectUserAction: (userAction: string) => void
}

const VariableDetailsView = ({ selectedVariableId, selectedVariableDetails, clearSelectedVariable, isLoadingVariableDetails, detectUserAction }: VariableDetailsViewProps) => {

  return (
    <>

      {isLoadingVariableDetails ? <Spinner /> :
        <>
          <TopSection
            selectedVariableId={selectedVariableId}
            selectedVariableDetails={selectedVariableDetails}
            clearSelectedVariable={clearSelectedVariable}
            onUserAction={detectUserAction}
          />
          <FormSectionView
            selectedVariableDetails={selectedVariableDetails}
          />

        </>
      }
    </>
  )
}

export default observer(VariableDetailsView)
