import { TdsDropdown, TdsDropdownOption } from "@scania/tegel-react";
import { debounce } from "lodash";
import type { JSX } from "@scania/tegel";
import { Field, FieldInputProps, useForm, useFormState } from "react-final-form";
import { createRef, useEffect } from "react";
import { composeValidators, requiredValidator } from "../../helpers/validationHelper";
import { getIn } from "final-form";
type ListItem = {
    name: string;
    value: any;
};

interface GenericDropdownParams extends JSX.TdsDropdown {
    onDropdownChange?: (newValue: string, oldValue?: string) => void;
    listData?: Array<ListItem>;
    dataFieldName: string;
    required?: boolean;
    className?: string;
}

const GenericDropdown = (params: GenericDropdownParams) => {
    const { listData, dataFieldName, required, onDropdownChange } = params;
    const dropDownRef = createRef<HTMLTdsDropdownElement>();
    const { initialValues, values } = useFormState();
    const { isValidationPaused } = useForm();

    const requiredFieldValidator = (value: string) => {
        if (!isValidationPaused() && required) {
            return requiredValidator(dataFieldName, value);
        }
        return "";
    }

    useEffect(() => {
        const initialValue = getIn(initialValues, dataFieldName)
        if (dropDownRef.current && initialValues && initialValue) {
            if (initialValue === values[dataFieldName]) {
                try {
                    dropDownRef.current.setValue(initialValue).then();
                } catch (error) {
                    console.error(error);
                }
            }
        }
        //eslint-disable-next-line
    }, [initialValues])
    useEffect(() => {
        const formValue = getIn(values, dataFieldName)
        if (dropDownRef.current && values && formValue) {
            const currentValue = dropDownRef.current.getAttribute("value")
            if (currentValue !== formValue) {
                try {
                    dropDownRef.current.setValue(formValue).then();
                } catch (error) {
                    console.error(error);
                }
            }
        }
        //eslint-disable-next-line
    }, [values])

    const onTdsChange = (newValue: any, input: FieldInputProps<any, HTMLElement>) => {
        const oldValue = input.value;
        if ((oldValue !== newValue) && onDropdownChange) {
            onDropdownChange(oldValue);
        }
        input.onChange(newValue);
    }

    return (
        <Field name={dataFieldName} validate={composeValidators(requiredFieldValidator)}
            render={({ input, meta }) => (
                <TdsDropdown
                    ref={dropDownRef}
                    className={params.className}
                    label={params.label}
                    labelPosition={params.labelPosition}
                    placeholder={params.placeholder}
                    size={params.size}
                    modeVariant={params.modeVariant}
                    openDirection={params.openDirection}
                    onTdsChange={debounce(({ detail }: TdsDropdownEvent) => { onTdsChange(detail.value, input) }, 300)}
                    helper={meta.touched && meta.error ? meta.error : undefined}
                    error={meta.touched && meta.error}
                    onBlur={input.onBlur}
                    defaultValue={initialValues[dataFieldName]}
                >
                    {listData && listData.map((item) => (
                        <TdsDropdownOption key={item.value} value={item.value} className="confirm-dialog">{item.name}</TdsDropdownOption>
                    ))}
                </TdsDropdown>
            )}
        />
    );
};

export default GenericDropdown;
