import React from "react";
import SearchBar from "./SearchBar/SearchBar";
import { TdsIcon, TdsTable, TdsTableBody, TdsTableHeader } from "@scania/tegel-react";
import VariableListRow from "./VariableListRow/VariableListRow";
import "./VariableList.scss"
import { VariableType } from "../../stores/variable-list-store";
import Spinner from "../Spinner/Spinner";

type VariableListProps = {
    tableData: VariableType[],
    setSearchId: (value: string) => void,
    setSearchName: (value: string) => void,
    setSearchVersion: (value: string) => void,
    handleRowClick: (id: number) => void,
    isLoadingVariableList: boolean,
}

const VariableList = ({ tableData, setSearchId, setSearchName, setSearchVersion, handleRowClick, isLoadingVariableList }: VariableListProps) => {

    return <>

        <div className="ListHeader">
            <div className="listHeaderItem fixed">
                {"ID"}
                {<SearchBar setSearchedValue={setSearchId} />}
            </div>
            <div className="listHeaderItem flexible">
                {"Variable name"}
                {<SearchBar setSearchedValue={setSearchName} />}
            </div>
            <div className="listHeaderItem fixed">
                {"Version"}
                {<SearchBar setSearchedValue={setSearchVersion} />}
            </div>
            <div className="listHeaderItem fixedIcon">
                <button id="filter-variable-list">
                    <TdsIcon name="filters" size="30px" />
                </button>
            </div>
        </div>
        <div className="variableListData">
            {isLoadingVariableList && <Spinner />}
            <TdsTable
                tableId="sortable-table"
                noMinWidth
                responsive
            >
                <TdsTableHeader></TdsTableHeader>
                <TdsTableBody>
                    {tableData && tableData.length > 0 && tableData.map((row: any) => (
                        <VariableListRow
                            id={row.id}
                            showDetails={() => handleRowClick(row.id)}
                            key={row.id}
                            name={row.name}
                            version={row.version}
                            type={row.type}
                        />
                    ))}
                </TdsTableBody>
            </TdsTable>
        </div>

    </>
}

export default VariableList;