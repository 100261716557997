const isNotUpdateable = (mode: string, fieldName: string, updateWithVersion: boolean) => {

    if(mode === 'edit' && fieldName === 'Variable type'){
        return true
    }
    else if (mode === 'create' || mode === 'edit') {
        return false
    } 
    if (mode === 'update') {
        if (updateWithVersion) {
            if (fieldName === 'Variable type' || fieldName === 'Variable name') {
                return true
            }
            else return false
        }
        else {
            if (fieldName === 'Description' || fieldName === 'Current status name' || fieldName === 'RFMS name') {
                return false
            }
            else return true
        }
    }
    return true

}

export { isNotUpdateable }