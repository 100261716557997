import { TdsTextField } from "@scania/tegel-react"
import { persistDropdownList, shouldRemoveRedundatSamplesDropdownList } from '../../../helpers/genericDropdownList';
import { getDisplayValueForTextFields } from "../../../helpers/getDisplayValue"
import { isNotUpdateable } from "../../../helpers/isNotUpdateable"
import { observer } from "mobx-react";
import GenericDropdown from "../../GenericDropdown/GenericDropdown"
import "./TimeSeries.scss"
import TextField from "../../TextField/TextField"
interface TimeSeriesProps {
    variableData?: VariableDetail
    mode: string
    isUpdateWithVersion: boolean
}

const TimeSeries = ({ variableData, mode, isUpdateWithVersion }: TimeSeriesProps) => {
    return (
        <>
            <div className='tds-row tds-u-mt3'>
                <div className='tds-col-sm-4'>
                    <TextField
                        label="Unit"
                        size="md"
                        label-position="outside"
                        placeholder="Enter unit of measurement"
                        value={getDisplayValueForTextFields(variableData?.unit, mode)}
                        modeVariant="secondary"
                        readOnly={isNotUpdateable(mode, 'Unit', isUpdateWithVersion)}
                        dataFieldName="unit"
                    />
                </div>
                <div className='tds-col-sm-4'>
                    {isNotUpdateable(mode, 'Persist', isUpdateWithVersion) ?
                        <TdsTextField
                            type="text"
                            size="md"
                            state="default"
                            modeVariant="secondary"
                            label="Persist *"
                            labelPosition="outside"
                            readOnly={true}
                            value={variableData?.persist === '1' ? "True" : "False"}>
                        </TdsTextField>
                        : <GenericDropdown
                            label="Persist *"
                            size="md"
                            labelPosition="outside"
                            placeholder="Select a persist value"
                            modeVariant="secondary"
                            listData={persistDropdownList}
                            dataFieldName="persist"
                        />
                    }
                </div>
                <div className='tds-col-sm-4'>
                    {isNotUpdateable(mode, 'shld_remove_samples', isUpdateWithVersion) ?
                        <TdsTextField
                            type="text"
                            size="md"
                            state="default"
                            modeVariant="secondary"
                            label="Should remove redundant samples"
                            labelPosition="outside"
                            readOnly={true}
                            value={getDisplayValueForTextFields(variableData?.should_remove_redundant_samples, mode)}
                        />
                        : <GenericDropdown
                            label="Should remove redundant samples"
                            size="md"
                            labelPosition="outside"
                            placeholder="Select a value"
                            defaultValue={JSON.stringify(variableData?.should_remove_redundant_samples)}
                            modeVariant="secondary"
                            listData={shouldRemoveRedundatSamplesDropdownList}
                            dataFieldName="should_remove_redundant_samples"
                        />
                    }
                </div>
            </div>
            <div className='tds-row tds-u-mt3'>
                <div className='tds-col-sm-6'>
                    <TextField
                        type="number"
                        label="Max samples *"
                        size="md"
                        label-position="outside"
                        placeholder="Enter maximum samples value"
                        value={getDisplayValueForTextFields(variableData?.max_samples, mode)}
                        modeVariant="secondary"
                        readOnly={isNotUpdateable(mode, 'Max samples', isUpdateWithVersion)}
                        dataFieldName="max_samples"
                        required={true}
                    />
                </div>
                <div className='tds-col-sm-6'>
                    <TextField
                        type="number"
                        label="Sample interval ms"
                        size="md"
                        placeholder="Enter sample interval value in ms"
                        label-position="outside"
                        value={getDisplayValueForTextFields(variableData?.sample_interval_ms, mode)}
                        modeVariant="secondary"
                        readOnly={isNotUpdateable(mode, 'Sample interval ms', isUpdateWithVersion)}
                        dataFieldName="sample_interval_ms"
                    />
                </div>
            </div>
        </>
    );
}

export default observer(TimeSeries)