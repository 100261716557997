import React from "react";
import "./VariableVersionIcon.scss";

const VariableVersionIcon = ({ version }: { version: string }) => {
    return (
        <>
            <div className="versionIcon">
                {version}
            </div>
        </>
    );
}

export default VariableVersionIcon;