import React, { MouseEventHandler } from 'react'
type WarningIconPropsType = {
  onMouseOver?: MouseEventHandler
  color?:string
}

const WarningSvg = ({ color, onMouseOver }: WarningIconPropsType) => {
  return (

<svg width="312" height="78" viewBox="0 0 312 78" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="312" height="77.6041" fill="#1D2229"/>
<path d="M257.219 18.2132C266.209 26.5691 274.944 34.6874 284.139 43.2326C284.139 34.8941 284.139 27.0466 284.139 19.074C286.816 19.074 289.136 19.074 291.652 19.074C291.652 32.3815 291.652 45.6098 291.652 59.6451C282.499 51.4071 273.778 43.5573 264.631 35.3249C264.631 43.5002 264.631 51.0571 264.631 58.835C262.096 58.835 259.781 58.835 257.219 58.835C257.219 45.4981 257.219 32.1178 257.219 18.2132Z" fill="white"/>
<path d="M225.13 59.8375C213.032 59.8002 204.415 51.1648 204.406 39.0689C204.396 26.9349 213.269 18.1316 225.421 18.2187C237.372 18.305 246.079 27.1812 246.009 39.2082C245.937 51.2622 237.22 59.8747 225.13 59.8375ZM238.364 38.9105C238.286 31.5334 232.071 25.2316 225.005 25.367C217.874 25.5032 211.896 31.8929 211.999 39.266C212.104 46.6812 218.222 52.8721 225.36 52.7858C232.472 52.6995 238.442 46.3288 238.364 38.9105Z" fill="white"/>
<path d="M176.715 58.835C176.715 47.8397 176.715 36.9079 176.715 25.7891C171.663 25.7891 166.929 25.7891 161.96 25.7891C161.96 23.503 161.96 21.3601 161.96 19.0748C174.281 19.0748 186.597 19.0748 199.076 19.0748C199.076 21.197 199.076 23.3786 199.076 25.7891C194.275 25.7891 189.492 25.7891 184.476 25.7891C184.476 37.0148 184.476 47.7645 184.476 58.835C181.912 58.835 179.585 58.835 176.715 58.835Z" fill="white"/>
<path d="M144.764 44.5059C146.192 41.7019 147.488 39.1568 149.046 36.0978C152.984 43.8305 156.745 51.1569 160.656 58.835C157.655 58.835 155.04 58.835 152.112 58.835C149.747 54.2033 147.287 49.4465 144.764 44.5059Z" fill="white"/>
<path d="M133.741 19.074C136.735 19.074 139.269 19.074 142.32 19.074C135.442 32.5977 128.725 45.6549 122.023 58.835C119.148 58.835 116.508 58.835 113.445 58.835C120.29 45.373 127.016 32.2991 133.741 19.074Z" fill="white"/>
<path d="M68.6636 19.074C73.6438 19.074 88.5255 18.9663 90.9843 19.074C92.7613 19.1516 94.5026 19.3416 96.1964 19.9213C102.815 22.1845 106.219 29.8673 105.302 36.5167C105.016 38.5875 104.263 40.5671 103.131 42.3219C101.876 44.2684 100.219 45.6716 98.481 47.1611C100.424 51.0152 102.323 54.7774 104.369 58.835C101.419 58.835 98.7423 58.835 95.867 58.835C94.1716 55.5598 92.4097 52.1571 90.5923 48.6459C85.8553 48.6459 81.2228 48.6459 76.2269 48.6459C76.2269 52.0811 76.2269 55.3499 76.2269 58.835C73.6089 58.835 71.2151 58.835 68.6628 58.835C68.6636 45.693 68.6636 32.5415 68.6636 19.074ZM89.8511 41.8302C92.4271 41.7217 94.9389 40.5933 96.4372 38.4425C97.8752 36.3781 98.2149 33.6319 97.4151 31.257C96.5948 28.8212 94.6182 26.8874 92.1238 26.2175C90.8631 25.8794 89.5067 25.8382 88.2096 25.7883C86.7533 25.7321 78.764 25.7883 76.2269 25.7883C76.2269 31.1169 76.2269 36.4438 76.2269 41.8302" fill="white"/>
<path d="M42.2054 25.7883C42.2054 37.0528 42.2054 47.7629 42.2054 58.8342C39.6564 58.8342 37.3346 58.8342 34.6707 58.8342C34.6707 48.0448 34.6707 37.0512 34.6707 25.7883C29.5599 25.7883 24.773 25.7883 19.7969 25.7883C19.7969 23.4222 19.7969 21.3522 19.7969 19.074C32.0615 19.074 44.3617 19.074 56.8877 19.074C56.8877 21.136 56.8877 23.4824 56.8877 25.7883C52.0944 25.7883 47.3717 25.7883 42.2054 25.7883Z" fill="white"/>
</svg>

);
}
export default WarningSvg;
