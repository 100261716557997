import { observable, action, makeObservable, runInAction } from "mobx"
import { createVariableData, getVariableDetailsById, updateVariableDataById } from "../adapters/VariableDetailsAdapter"
import { RootStoreType } from ".";

import { newVariable, transformToAPIFriendlyData, transformToUIFriendlyData } from "../helpers/dataHelper";


export class VariableDetailsStore {
  selectedVariable: number = -1;
  selectedVariableDetails: VariableDetail = newVariable
  isLoadingVariableDetails: boolean = false
  error: string | null = null;
  toastData: ToastData | null = { message: "Test Message", variant: "error" };
  rootStore: RootStoreType | null = null;
  isSaveSuccessfull: boolean = false
  isVariableUpdateMode: boolean = false
  variableUpdateMode: string = ""
  variableFetchError: string = ""

  constructor(root: RootStoreType) {
    this.rootStore = root;
    return makeObservable(this, {
      selectedVariable: observable,
      selectedVariableDetails: observable,
      error: observable,
      isSaveSuccessfull: observable,
      variableUpdateMode: observable,
      isVariableUpdateMode: observable,
      toastData: observable,
      variableFetchError: observable,
      selectVariableForViewing: action,
      setSelectedVariableDetails: action,
      clearSelectVariable: action,
      fetchDetailsOfSelectedVariable: action,
      createVariableDetails: action,
      updateVariableDetails: action,
      activateCreateMode: action,
      setVariableUpdateMode: action,
      setIsVariableUpdateMode: action,
      resetSaveSuccessFlag: action
    })
  }

  selectVariableForViewing = (id: number) => {
    if (id !== 0) {
      this.selectedVariable = id;
      this.fetchDetailsOfSelectedVariable();
    }
    else {
      this.selectedVariable = id;
    }

  }

  setSelectedVariableDetails = (variableDetail: VariableDetail) => {
    this.selectedVariableDetails = variableDetail;
  }

  clearSelectVariable = () => {
    this.selectedVariable = -1;
  }

  fetchDetailsOfSelectedVariable = async () => {

    if (!this.isLoadingVariableDetails) {
      this.isLoadingVariableDetails = true
      this.variableFetchError = "";
      try {
        const response = await getVariableDetailsById(this.selectedVariable)
        if (response && response.data && response.data.value_names) {
          response.data = transformToUIFriendlyData(response.data);
          runInAction(() => {
            this.selectedVariableDetails = response.data;
            this.isLoadingVariableDetails = false
          });
        }
      } catch (error: any) {
        console.log('error', error)
        this.isLoadingVariableDetails = false
        runInAction(() => {
          if (error.isAxiosError) {
            if (error.response?.status === 404) {
              this.variableFetchError = "Variable Not Found!"
            } else if (error.response.status === 500) {
              this.variableFetchError = "Something went wrong while retrieving variable details. Please try again or contact Variable Registry Support"
            }
          }
        });
      }
    }
  }

  createVariableDetails = async (requestPayload: any) => {
    this.isLoadingVariableDetails = true;
    try {
      requestPayload = transformToAPIFriendlyData(requestPayload)
      const response = await createVariableData(requestPayload);
      runInAction(() => {
        this.variableUpdateMode = ""
        this.isLoadingVariableDetails = false;
        this.rootStore?.toastStore?.addToast("Created variable successfully", "success");
        this.selectedVariable = response.data
        this.isSaveSuccessfull = true;
        this.rootStore?.variableListStore.loadVariables();
        this.fetchDetailsOfSelectedVariable();
      });
    } catch (error: any) {
      runInAction(() => {
        this.error = error.message;
        this.isLoadingVariableDetails = false;
        this.rootStore?.toastStore?.addToast("Create variable failed with message " + error.message, "error");
      });
    }
  }

  updateVariableDetails = async (id: number, requestPayload: any) => {
    this.isLoadingVariableDetails = true;
    try {
      requestPayload = transformToAPIFriendlyData(requestPayload)
      await updateVariableDataById(id, requestPayload);
      runInAction(() => {
        this.variableUpdateMode = ""
        this.isLoadingVariableDetails = false;
        this.isSaveSuccessfull = true;
        this.rootStore?.toastStore?.addToast("Variable successfully updated.", "success");
        this.rootStore?.variableListStore.loadVariables();
        this.fetchDetailsOfSelectedVariable()
      });
    } catch (error: any) {
      runInAction(() => {
        this.error = error.message;
        this.isLoadingVariableDetails = false;
        this.rootStore?.toastStore?.addToast("Variable updateing failed with message " + error.message, "error", 5);
      });
    }
  }

  activateCreateMode = () => {
    this.selectedVariable = 0;
    this.selectedVariableDetails = newVariable;
  }

  setVariableUpdateMode = (newUpdateMode: string) => {
    this.variableUpdateMode = newUpdateMode;
  }

  setIsVariableUpdateMode = (value: boolean) => {
    this.isVariableUpdateMode = value;
  }
  resetSaveSuccessFlag = () => {
    this.isSaveSuccessfull = false
  }

}