import { createContext } from "react";
import { VariableListStore } from "./variable-list-store";
import { UserProfileStore } from "./user-profile-store";
import { VariableDetailsStore } from "./variable-details-store";
import ToastStore from "./toast-store";


export class RootStoreType {
  userProfileStore: UserProfileStore;
  variableListStore: VariableListStore;
  variableDetailsStore: VariableDetailsStore;
  toastStore: ToastStore | null

  constructor() {
    // Bcause of passing this to sub stores, all stores can access RootStore and other stores
    this.userProfileStore = new UserProfileStore()
    this.variableListStore = new VariableListStore(this)
    this.variableDetailsStore = new VariableDetailsStore(this)
    this.toastStore = new ToastStore();
  }
}

export const RootStore = new RootStoreType();
export const RootStoreContext = createContext<RootStoreType>(RootStore);
