import React from 'react'
import { observer } from 'mobx-react'
import { TdsToast } from '@scania/tegel-react'
import { useMobXStore } from '../../hooks/useMobXStore'

const ToastContainer = () => {
  const { toastStore } = useMobXStore()

  return (
    <div className="tds-u-absolute tds-u-right0">
      {toastStore?.toastQueue.map((toast: ToastData, index) => {
        return <TdsToast key={`toast-${index}`}
          variant={toast.variant}
          header={toast.message}

          closable={true}
        />
      })}
    </div>
  )
}

export default observer(ToastContainer);