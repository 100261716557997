import "./FormSection.scss"
import GenericFields from "../GenericFields/GenericFields"
import { observer } from "mobx-react";
import { TdsAccordion, TdsAccordionItem } from "@scania/tegel-react";
import ChainEditWrapper from "../../ChainEdit/ChainEditWrapper";
import ValueNamesEdit from "../../ValueNamesEdit/ValueNamesEdit";
import Histogram from "../Histogram/Histogram";
import Trigger from "../Trigger/Trigger";
import TimeSeries from "../TimeSeries/TimeSeries";
import Gauge from "../Gauge/Gauge";
import { VARIABLE_UPDATE_MODES ,VARIABLE_TYPES}  from "../../../helpers/constants";
import { useLocation } from "react-router-dom";
import ValueNamesDisplay from "../../ValueNamesDisplay/ValueNamesDisplay";
import ChainDisplay from "../../ChainDisplay/ChainDisplay";

import {useFormState } from "react-final-form";
// import { useEffect } from "react";
// import { resetFormValidations } from "../../../helpers/validationHelper";

type FormSectionProps = {
  selectedVariableDetails?: VariableDetail | undefined
  variableUpdateMode: string
  isVariableUpdateMode: boolean
}

const FormSection = ({ selectedVariableDetails, variableUpdateMode, isVariableUpdateMode }: FormSectionProps) => {

  const { pathname } = useLocation();
  const { values } = useFormState()
  // const { batch,resetFieldState } = useForm();
  const mode = pathname.toLowerCase().endsWith('create') ? "create" : (isVariableUpdateMode ? "update" : "edit");
  const isUpdateWithVersion = variableUpdateMode === VARIABLE_UPDATE_MODES.WithVersion ? true : false;
  const variableType = values.type

  // useEffect(() => {
  //   batch(() => {
  //     resetFormValidations(resetFieldState)
  //   });
  //   // eslint-disable-next-line
  // }, [values.type])

  return <div className={`tds-container-fluid tds-background-grey-50 tds-u-p1 tds-u-pt2 tds-u-mt1 form-container ${mode}`}>
    <GenericFields mode={mode} isUpdateWithVersion={isUpdateWithVersion} />
    {variableType === VARIABLE_TYPES.GAUGE && <Gauge mode={mode} isUpdateWithVersion={isUpdateWithVersion}></Gauge>}
    {variableType === VARIABLE_TYPES.TRIGGER && <Trigger mode={mode} isUpdateWithVersion={isUpdateWithVersion}></Trigger>}
    {variableType === VARIABLE_TYPES.HISTOGRAM && <Histogram mode={mode} isUpdateWithVersion={isUpdateWithVersion}></Histogram>}
    {variableType === VARIABLE_TYPES.TIME_SERIES && <TimeSeries mode={mode} isUpdateWithVersion={isUpdateWithVersion}></TimeSeries>}
    {!variableType && <div>Variable type information missing</div>}

    {selectedVariableDetails && <TdsAccordion className="tds-u-mt3">
      {variableType === VARIABLE_TYPES.GAUGE && <TdsAccordionItem header="Value names" expanded paddingReset >
        {variableUpdateMode === VARIABLE_UPDATE_MODES.WithoutVersion && <ValueNamesDisplay valueNamesObject={selectedVariableDetails?.value_names} />}
        {(variableUpdateMode === VARIABLE_UPDATE_MODES.WithVersion || ["create", "edit"].includes(mode)) && <ValueNamesEdit />}
      </TdsAccordionItem>}
      <TdsAccordionItem header="Chain *" expanded paddingReset>
        {variableUpdateMode === VARIABLE_UPDATE_MODES.WithoutVersion && <ChainDisplay chainData={selectedVariableDetails?.chain} />}
        {(variableUpdateMode === VARIABLE_UPDATE_MODES.WithVersion || ["create", "edit"].includes(mode)) && <ChainEditWrapper />}
      </TdsAccordionItem>
    </TdsAccordion>}
  </div>
}

export default observer(FormSection)

