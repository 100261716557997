import './VariableDetails.scss'
import TopSection from '../TopSection/TopSection'
import FormSection from './FormSection/FormSection'
import Spinner from '../Spinner/Spinner'
import { observer } from 'mobx-react'
import { useForm } from 'react-final-form'
import { useEffect } from 'react'

type VariableDetailsProps = {
  selectedVariableId: number
  selectedVariableDetails: VariableDetail | undefined
  clearSelectedVariable: () => void
  isLoadingVariableDetails?: boolean
  detectUserAction: (userAction: string) => void
  variableUpdateMode: string
  isVariableUpdateMode: boolean
}

const VariableDetails = ({ selectedVariableId, selectedVariableDetails, clearSelectedVariable, isLoadingVariableDetails, detectUserAction, variableUpdateMode, isVariableUpdateMode }: VariableDetailsProps) => {

  const { pauseValidation, isValidationPaused, resumeValidation, reset } = useForm()
  const onDetectUserAction = (userAction: string) => {
    if (!['Created', 'Save'].includes(userAction)) {
      pauseValidation();
    } else {
      if (isValidationPaused()) {
        resumeValidation();
      }
    }
    detectUserAction(userAction);
  }
  useEffect(() => {
    if (selectedVariableDetails) {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [selectedVariableDetails])
  return (
    <>
      {isLoadingVariableDetails ? <Spinner /> :
        <>
          <TopSection
            selectedVariableId={selectedVariableId}
            selectedVariableDetails={selectedVariableDetails}
            clearSelectedVariable={clearSelectedVariable}
            onUserAction={onDetectUserAction}
            reset={reset}
          />
          <FormSection
            selectedVariableDetails={selectedVariableDetails}
            variableUpdateMode={variableUpdateMode}
            isVariableUpdateMode={isVariableUpdateMode}
          />
        </>
      }
    </>
  )
}

export default observer(VariableDetails)
