import React, { useEffect, useState } from "react"
import { TdsTextField } from "@scania/tegel-react"
import "./GenericFields.scss"
import { scopeValues, variableTypesList } from "../../../helpers/genericDropdownList"
import { useForm, useFormState } from "react-final-form"
import { getDisplayValueForTextFields } from "../../../helpers/getDisplayValue"
import { isNotUpdateable } from "../../../helpers/isNotUpdateable"
import { useMobXStore } from "../../../hooks/useMobXStore"
import { observer } from "mobx-react"
import GenericDropdown from "../../GenericDropdown/GenericDropdown"
import TextField from "../../TextField/TextField"
import TextArea from "../../TextArea/TextArea"
import ConfirmationDialog from "../ConfirmationDialog/ConfirmationDialog"

type GenericFieldsProps = {
    mode: string
    isUpdateWithVersion: boolean
}

const GenericFields = ({ mode, isUpdateWithVersion }: GenericFieldsProps) => {
    const { initialValues, values } = useFormState();
    const { reset, resetFieldState } = useForm();
    const form = useForm();
    const { variableDetailsStore } = useMobXStore()
    const [showConfirmationDialog, setShowConfirmationDialog] = useState<boolean>(false);
    const [oldVariableTypeValue, setOldVariableTypeValue] = useState<VariableType>('gauge');
    const { selectedVariableDetails } = variableDetailsStore;

    const getValues = (dataFieldname: string) => {
        return values[dataFieldname];
    }
    const setValue = (dataFieldname: string, value: any) => {
        form.change(dataFieldname, value)
    }

    const changeVariableTypeAndResetForm = (oldValue: string) => {
        setOldVariableTypeValue(oldValue as VariableType);
        setShowConfirmationDialog(true);
    }

    const onCancel = () => {
        setValue('type', oldVariableTypeValue);
        setShowConfirmationDialog(false);
    }

    const handleConfirmationStatuschange = () => {

        // Resetting the values except generic form fields and any validation messages we have. 
        const newFormValues = values;
        Object.keys(newFormValues).forEach((key: string) => {
            if (!['current_status_name', 'minimum_cdc_version', 'name', 'description', 'rfms_name', 'type', 'scope'].includes(key)) {
                newFormValues[key] = initialValues[key]
            } else if (['current_status_name', 'minimum_cdc_version', 'name', 'description', 'rfms_name', 'type'].includes(key)) {
                resetFieldState(key);
            }
            if (key === 'chain') {
                newFormValues.chain.forEach((item: any, index: any) => {
                    resetFieldState(`chain[${index}].source`);
                });
            }

        });
        reset(newFormValues);
        setShowConfirmationDialog(false);
    }

    useEffect(() => {
        const selectedType = getValues("type") as VariableType;
        setValue('scope', scopeValues[selectedType]);
        if (mode !== 'create') setValue('id', selectedVariableDetails?.id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mode]);

    useEffect(() => {
        if (["create"].includes(mode)) {
            setValue("scope", scopeValues[values.type as VariableType])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values.type])

    return <>
        <div className='tds-row'>
            <div className="tds-col-sm-6">
                {(isNotUpdateable(mode, 'Variable type', isUpdateWithVersion)) ?
                    <TdsTextField
                        type="text"
                        size="md"
                        state="default"
                        modeVariant="secondary"
                        label="Variable type *"
                        labelPosition="outside"
                        readOnly={true}
                        value={getDisplayValueForTextFields(selectedVariableDetails?.type, mode)}                    >
                    </TdsTextField>
                    :
                    <GenericDropdown
                        className="variable-type-dropdown"
                        label="Variable type *"
                        labelPosition="outside"
                        placeholder="Select type"
                        size="md"
                        modeVariant="secondary"
                        openDirection="down"
                        listData={variableTypesList}
                        dataFieldName="type"
                        onDropdownChange={changeVariableTypeAndResetForm}
                    />
                }

            </div>
            <div className="tds-col-sm-6">
                <TextField
                    type="text"
                    size="md"
                    state="default"
                    modeVariant="secondary"
                    placeholder="Enter the minimum CDC version required"
                    label="Minimum CDC version"
                    labelPosition="outside"
                    readOnly={isNotUpdateable(mode, 'Minimum CDC version', isUpdateWithVersion)}
                    dataFieldName="minimum_cdc_version"
                />
            </div>
        </div>
        <div className="tds-row tds-u-mt3">
            <div className="tds-col-sm-12">
                <TextField
                    type="text"
                    size="md"
                    modeVariant="secondary"
                    placeholder="Enter a name "
                    label="Variable name *"
                    labelPosition="outside"
                    readOnly={isNotUpdateable(mode, 'Variable name', isUpdateWithVersion)}
                    dataFieldName="name"
                    required={true}
                />
            </div>
        </div>
        <div className="tds-row tds-u-mt3">
            <div className="tds-col-sm-6">
                <TextField
                    type="text"
                    size="md"
                    modeVariant="secondary"
                    placeholder="Enter current status name for the variable"
                    label="Current status name"
                    labelPosition="outside"
                    readOnly={isNotUpdateable(mode, 'Current status name', isUpdateWithVersion)}
                    dataFieldName="current_status_name"
                />
            </div>
            <div className="tds-col-sm-6">
                <TextField
                    type="text"
                    size="md"
                    modeVariant="secondary"
                    placeholder="Enter RFMS name for the variable"
                    label="RFMS name"
                    labelPosition="outside"
                    readOnly={isNotUpdateable(mode, 'RFMS name', isUpdateWithVersion)}
                    dataFieldName="rfms_name" />
            </div>
        </div>
        <div className="tds-row tds-u-mt3">
            <div className="tds-col-sm-12">

                <TextArea dataFieldName="description"
                    state="default"
                    rows={2}
                    modeVariant="secondary"
                    placeholder="Enter a description for the variable you are creating"
                    label="Description"
                    labelPosition="outside"
                    readOnly={isNotUpdateable(mode, 'Description', isUpdateWithVersion)}
                />
            </div>
        </div>
        {showConfirmationDialog &&
            <ConfirmationDialog
                header="Confirm Variable status change"
                onCancel={onCancel}
                onConfirm={handleConfirmationStatuschange}
            >
                <>
                    <p className="tds-body-01">Are you sure you want to change the  variable to type <strong>{values['type']}</strong>.</p>
                    <p className="tds-body-02">
                        <strong>NOTE </strong>: Once confirmed, the form values except for <strong>Minimum CDC version, Variable name, Current Status Name, RFMS Name & Description</strong> will be reset.
                    </p>
                </>
            </ConfirmationDialog>
        }
    </>
}

export default observer(GenericFields)