import React, { createRef, useEffect } from 'react'
import { TdsHeader, TdsHeaderBrandSymbol, TdsHeaderDropdown, TdsHeaderDropdownList, TdsHeaderDropdownListItem, TdsHeaderDropdownListUser, TdsHeaderTitle } from '@scania/tegel-react'
import { useMobXStore } from '../../hooks/useMobXStore'
import { msalInstance } from '../../authConfig'
import { observer } from 'mobx-react'
import { useIsAuthenticated } from '@azure/msal-react'

const Header = () => {
  const isAuthenticated = useIsAuthenticated();
  const { userProfileStore } = useMobXStore();
  const { userProfile } = userProfileStore;
  const imageRef = createRef<HTMLImageElement>()

  const getAllRoles = (roles:string[] | undefined):string =>{
    if(roles && roles.length > 0)
      return roles.join(', ');
    else
      return ' No role assigned';
  }

  useEffect(() => {
    var urlCreator = window.URL || window.webkitURL;
    if (imageRef && imageRef.current && userProfileStore.userPhoto) {
      imageRef.current.src = urlCreator.createObjectURL(userProfileStore.userPhoto);
    }
  }, [userProfileStore.userPhoto, imageRef])

  return (
    <TdsHeader>
      <TdsHeaderTitle>CDC Variable Editor</TdsHeaderTitle>
      <TdsHeaderDropdown slot="end" class="demo-hide demo-xs-show" no-dropdown-icon style={{ display: !isAuthenticated ? 'none' : "" }}>
        <img ref={imageRef}
          slot="icon"
          src={"https://www.svgrepo.com/show/384676/account-avatar-profile-user-6.svg"}
          alt="User menu."
        />
        <TdsHeaderDropdownList size="lg">
          <TdsHeaderDropdownListUser
            header={userProfile ? userProfile.displayName : "LastName, FirstName"}
            subheader={'Role : ' + getAllRoles(userProfileStore?.roles)}
          ></TdsHeaderDropdownListUser>
          <TdsHeaderDropdownListItem>
            <a href="/" role="button" onClick={() => msalInstance.logoutRedirect()} >Logout</a>
          </TdsHeaderDropdownListItem>
        </TdsHeaderDropdownList>
      </TdsHeaderDropdown>
      <TdsHeaderBrandSymbol slot="end">
        <a aria-label="Scania - red gryphon on blue shield" href="/"></a>
      </TdsHeaderBrandSymbol>
    </TdsHeader>
  )
}

export default observer(Header)