import { useUserRole } from "../../hooks/useUserRole";
import UnauthorisedDisplay from "./UnauthorisedDisplay";

interface ProtectedRoutePropType {
  allowedRoles: Array<string>
  children?: React.ReactNode
}
const ProtectedRoute = ({ allowedRoles, children }: ProtectedRoutePropType) => {
  const { userRole } = useUserRole();
  return allowedRoles.includes(userRole) ? <>{children}</> : <UnauthorisedDisplay />;
};

export default ProtectedRoute