import VariableVersionIcon from "../VariableVersionIcon/VariableVersionIcon"
import "./TopSection.scss"
import { TdsButton, TdsIcon } from "@scania/tegel-react"
import { useLocation, useNavigate } from "react-router-dom"
import DropdownButton from "../VariableDetails/DropdownButton/DropdownButton"
import { observer } from "mobx-react"
import TopSectionButtons from "./TopSectionButtons"

type TopSectionProps = {
  selectedVariableId: number
  selectedVariableDetails?: VariableDetail
  clearSelectedVariable: () => void
  onUserAction: (userAction: string) => void
  reset?: () => void
}

const TopSection = ({ selectedVariableId, selectedVariableDetails, clearSelectedVariable, onUserAction, reset }: TopSectionProps) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isCreateMode = pathname.toLowerCase().endsWith('create');

  const returnToReadOnly = () => {
    reset && reset();
    navigate(`/${selectedVariableId}/view`, { replace: true })
  }

  const onCloseClick = () => {

    reset && reset();
    clearSelectedVariable()
    navigate('/', { replace: true })
  }

  return (
    <>
      {!isCreateMode && (
        <div>
          <div className="row">
            <span className="tds-u-gap2 left-aligned">
              <h5 className="blue-400">
                {" "}
                ID : {selectedVariableDetails?.id}{" "}
              </h5>
              <VariableVersionIcon
                version={`v${selectedVariableDetails?.version}`}
              />
              <h5 className="blue-400">
                {" "}
                Status : {selectedVariableDetails?.status}{" "}
              </h5>
            </span>
            <TdsButton
              type="button"
              className="right-aligned"
              variant="ghost"
              size="sm"
              onClick={onCloseClick}
            >
              <TdsIcon slot="icon" size="20px" name="cross" />
            </TdsButton>
          </div>
          <h3> {selectedVariableDetails?.name} </h3>

          <div className="row">
            <p>
              Last modified by{" "}
              <span className="blue-400">
                {selectedVariableDetails?.modifiedBy}
              </span>
            </p>
            <div className="action-buttons right-aligned">
              <TopSectionButtons variableStatus={selectedVariableDetails?.status} onUserAction={onUserAction} returnToReadOnly={returnToReadOnly} onCloseClick={onCloseClick} />

            </div>
          </div>
        </div>
      )}
      {isCreateMode && (
        <div className="row tds-u-mb2">
          <h3> Create Variable </h3>
          <div className="action-buttons right-aligned">
            <span className="tds-u-gap2 tds-row">
              <TdsButton
                variant="secondary"
                text="cancel"
                size="sm"
                onClick={onCloseClick}
              />
              <DropdownButton
                label="Select status"
                options={[{ name: "Save as draft", value: "Created" }, { name: "Move to test", value: "Test" }]}
                onSelect={(option) => { onUserAction(option) }}
                buttonColor="primary"
                behavior="submit"
              />
            </span>
          </div>
        </div>
      )}
    </>
  );
}

export default observer(TopSection)