
import { observable, action, makeObservable, runInAction } from "mobx"
export class ToastStore {

  toastQueue: ToastData[];

  constructor() {
    this.toastQueue = []
    return makeObservable(this, {
      toastQueue: observable,
      addToast: action
    });
  }

  addToast = (message: string, variant: 'success' | 'error' | 'warning' | 'information', timeoutInSeconds?: number) => {
    const timeout = (timeoutInSeconds || 3)* 1000
    this.toastQueue.push({ message, variant, timeoutInSeconds: timeout });
    setTimeout(() => {
      runInAction(() => {
        if (this.toastQueue.length > 0) {
          this.toastQueue.shift();
        }
      })
    }, timeout);
  }

}

export default ToastStore