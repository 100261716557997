import React from 'react'
import './ValueNamesDisplay.scss'
import { TdsTable, TdsTableHeader, TdsHeaderCell, TdsBodyCell, TdsTableBody, TdsTableBodyRow } from '@scania/tegel-react';

type ValueNamesDisplayPropType = {
  valueNamesObject?: Array<ValueNamesObjectType> | object
}

const ValueNamesDisplay = ({ valueNamesObject }: ValueNamesDisplayPropType) => {

  const valueNameArray = valueNamesObject as Array<ValueNamesObjectType>
  return (
    <div className='vn-display-container'>
      <TdsTable className="value-names-display-grid" compact-design={true} modeVariant="secondary">
        <TdsTableHeader>
          <TdsHeaderCell cell-key="decCode" cell-value="Code (Decimal)" custom-width="20vw"></TdsHeaderCell>
          <TdsHeaderCell cell-key="hexCode" cell-value="Code (Hexadecimal)" custom-width="20vw"></TdsHeaderCell>
          <TdsHeaderCell cell-key="name" cell-value="Name" custom-width="60vw"></TdsHeaderCell>
        </TdsTableHeader>
        <TdsTableBody>
          {valueNameArray && valueNameArray?.length > 0 ? (valueNameArray?.map((item, index) => {
            return <TdsTableBodyRow key={index}>
              <TdsBodyCell cell-key="decCodeValue" cell-value={parseInt(item.hexCode, 16)} text-align="start"></TdsBodyCell>
              <TdsBodyCell cell-key="hexCodeValue" cell-value={item.hexCode} text-align="start"></TdsBodyCell>
              <TdsBodyCell cell-key="nameValue" cell-value={item.name} text-align="start"></TdsBodyCell>
            </TdsTableBodyRow>
          })) : (
            <TdsTableBodyRow key={"no-data"}>
              <TdsBodyCell cell-key="decCodeValue" cell-value="No data" text-align="start"></TdsBodyCell>
              <TdsBodyCell cell-key="hexCodeValue" cell-value="" text-align="start"></TdsBodyCell>
              <TdsBodyCell cell-key="nameValue" cell-value="" text-align="start"></TdsBodyCell>
            </TdsTableBodyRow>
          )
          }
        </TdsTableBody>
      </TdsTable>
    </div>
  )
}

export default ValueNamesDisplay