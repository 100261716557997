import React from 'react';
import { TdsButton } from '@scania/tegel-react';
import TypeDropDown from './TypeDropDown';
import { ChainEditPropType } from './types';
import TextField from '../../TextField/TextField';

const TypeInput = ({ chainItem, onRemoveChainItemClick, onItemTypeChange, index, name }: ChainEditPropType) => {
  return <div className='tds-container-fluid tds-u-p1'>
    <div className='tds-row'>
      <div className="tds-col-sm-12 tds-u-mt1 tds-u-flex-end">
        <TdsButton text='Remove' variant='danger' size='xs' onClick={onRemoveChainItemClick} />
      </div>
    </div>
    <div className="tds-row">
      <div className="tds-col-sm-3">
        {index === 0 ?
          <TextField
            label='Type *'
            labelPosition='outside'
            size='md'
            value={chainItem?.type}
            dataFieldName={`${name}.type`}
            readOnly={true} />
          : <TypeDropDown
            onItemTypeChange={onItemTypeChange}
            dataFieldName={name} />
        }
      </div>
      <div className="tds-col-sm-3">
        <TextField label='Value type'
          value={chainItem?.value_type}
          labelPosition="outside"
          size='md'
          dataFieldName={`${name}.value_type`}
        />
      </div>
      <div className="tds-col-sm-6">
        <TextField
          label='CAN source address'
          labelPosition='outside'
          size='md'
          value={chainItem?.can_source_address}
          dataFieldName={`${name}.can_source_address`} />
      </div>
    </div>
    <div className="tds-row tds-u-mt2">
      <div className="tds-col-sm-6">
        <TextField
          label='Input signal'
          size='md'
          labelPosition='outside'
          value={chainItem?.input_signal}
          dataFieldName={`${name}.input_signal`}
        />
      </div>
      <div className="tds-col-sm-6">
        <TextField
          label='Input variable'
          size='md'
          value={chainItem?.input_variable}
          dataFieldName={`${name}.input_variable`}
        />
      </div>
    </div>
    <div className="tds-row tds-u-mt2">
      <div className="tds-col-sm-12">
        <TextField
          label='Source *'
          size='md'
          labelPosition='outside'
          value={chainItem?.source}
          dataFieldName={`${name}.source`}
          required={true}
        />
      </div>
    </div>
  </div>;
}

export default TypeInput