import React from 'react';
import { defineCustomElements } from '@scania/tegel-react';
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import VariableEditor from "./pages/VariableEditor/VariableEditor";
import { RootStore, RootStoreContext } from "./stores";
import './App.css';
import Layout from './pages/Layout';
import ProtectedRoute from './components/Authentication/ProtectedRoute';
import { EDITOR_ROLE, VIEWER_ROLE } from './hooks/useUserRole';
import HomePage from './pages/HomePage/Home';
import VariableViewer from './pages/VariableViewer/VariableViewer';
import CreateButton from './pages/HomePage/Create/Create';

defineCustomElements();

function App() {

  return (<div className="App">
    <RootStoreContext.Provider value={RootStore} >
      {/* <RBACProvider roles={["ScsCdcVariableViewer", "ScsCdcVariableEditor"]} > */}
      <Router>
        <Routes>
          <Route path="" element={<Layout />}>
            <Route path="/" element={
              <ProtectedRoute allowedRoles={[EDITOR_ROLE, VIEWER_ROLE]}>
                <HomePage />
              </ProtectedRoute>
            }>
              <Route index element={<CreateButton />} />
              <Route path="/create" element={
                <ProtectedRoute allowedRoles={[EDITOR_ROLE]}>
                  <VariableEditor isCreateMode={true} />
                </ProtectedRoute>
              } />
              <Route path="/:id/view" element={
                <ProtectedRoute allowedRoles={[EDITOR_ROLE, VIEWER_ROLE]}>
                  <VariableViewer />
                </ProtectedRoute>
              } />
              <Route path="/:id/edit" element={
                <ProtectedRoute allowedRoles={[EDITOR_ROLE]}>
                  <VariableEditor />
                </ProtectedRoute>
              } />

            </Route>

          </Route>
        </Routes>
      </Router>
    </RootStoreContext.Provider>
  </div>
  );
}

export default App;
