import React from 'react';
import { TdsButton } from '@scania/tegel-react';
import TypeDropDown from './TypeDropDown';
import { ChainItemTypeEnum } from '../../../helpers/dataHelper';


interface TypeOnlyPropType {
  chainItem?: ChainItemType
  onRemoveChainItemClick: () => void
  onItemTypeChange?: (newType: ChainItemTypeEnum) => void
  index: number
  name:string
}

const TypeOnly = ({ onRemoveChainItemClick, onItemTypeChange, chainItem, index,name }: TypeOnlyPropType) => {
  return <div className='tds-container-fluid tds-u-p1'>
      <div className='tds-row'>
        <div className="tds-col-md-12 tds-u-mt1 tds-u-flex-end">
          <TdsButton text='Remove' variant='danger' size='xs' onClick={onRemoveChainItemClick} />
        </div>
      </div>
      <div className="tds-row ">
        <div className="tds-col-md-3">
          <TypeDropDown onItemTypeChange={onItemTypeChange} dataFieldName={name}  />
        </div>
      </div>
    </div>;
}

export default TypeOnly