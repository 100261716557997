import React from "react";
import './VariableTypeIcon.scss'

interface VariableTypeIconProps {
    type: 'G' | 'H' | 'Ts' | 'Tr' | string;
    tooltipText?: 'Gauge' | 'Histogram' | 'Time Series' | 'Trigger' | 'Type is not available' | null;

};

const generateUniqueId = () => {
    return `id-${Math.random().toString(36).substring(2, 9)}`;
};

const VariableTypeIcon = ({ type, tooltipText }: VariableTypeIconProps) => {

    const uniqueId = generateUniqueId();

    return (
        <>
            <div className={"tds-background-blue-400 typeIcon"} data-tooltip={tooltipText} id={uniqueId}>
                <span>{type}</span>
            </div>
        </>
    )
}

export default VariableTypeIcon;