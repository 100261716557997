import React from 'react'

// Styles 
import './Layout.scss'
import { TdsButton } from '@scania/tegel-react'
import { loginRequest, msalInstance } from '../authConfig'
import { useIsAuthenticated } from '@azure/msal-react'
import { Navigate } from 'react-router-dom'
import TratonLogo from '../components/assets/TratonLogo'

const UnAuthenticated = () => {
  const isAuthenticated = useIsAuthenticated();
  const onLoginClick = () => {
    msalInstance.loginRedirect(loginRequest)
  }
  return (
    <>
      {isAuthenticated && <Navigate to='/' />}
      <div className='landing-page-container'>
        <TratonLogo />
        <div className='editor-title tds-headline-03 tds-u-mt2 tds-u-mb2'>Variable Editor</div>
        <div className='tds-headline-05 tds-u-mt2 '>Please login using your TRATON account credentials </div>
        <div className='tds-u-mt1 helper-text'>Note - Your access request must be approved before you can successfully login. If you haven’t requested access already, please do so on <a href="https://editor.variable-registry.devtest.gf.aws.scania.com/editor">url-to-be-updated-soon</a></div>
        <TdsButton className='tds-u-mt2' onClick={onLoginClick} variant="primary" size="lg" text="LOGIN"></TdsButton>
      </div>
    </>
  )
}

export default UnAuthenticated