import { TdsTextField } from "@scania/tegel-react"
import { observer } from "mobx-react";
import "./TimeSeriesView.scss"


interface TimeSeriesViewProps {
    variableData?: VariableDetail
}

const TimeSeriesView = ({ variableData }: TimeSeriesViewProps) => {

    return (
        <>
            <div className='tds-row tds-u-mt3'>
                <div className='tds-col-sm-4'>
                    <TdsTextField
                        type="text"
                        size="md"
                        state="default"
                        modeVariant="secondary"
                        label="Unit"
                        label-position="outside"
                        placeholder="Enter unit of measurement"
                        value={variableData?.unit}
                        readOnly={true}
                    />
                </div>
                <div className='tds-col-sm-4'>
                    <TdsTextField
                        type="text"
                        size="md"
                        state="default"
                        modeVariant="secondary"
                        label="Persist *"
                        labelPosition="outside"
                        readOnly={true}
                        value={variableData?.persist === '1' ? "True" : "False"}>
                    </TdsTextField>
                </div>
                <div className='tds-col-sm-4'>
                    <TdsTextField
                        type="text"
                        size="md"
                        state="default"
                        modeVariant="secondary"
                        label="Should remove redundant samples"
                        labelPosition="outside"
                        readOnly={true}
                        value={variableData?.should_remove_redundant_samples === undefined ? '' : `${variableData?.should_remove_redundant_samples}`}
                    />
                </div>
            </div>
            <div className='tds-row tds-u-mt3'>
                <div className='tds-col-sm-6'>
                    <TdsTextField
                        type="number"
                        size="md"
                        state="default"
                        modeVariant="secondary"
                        label="Max samples *"
                        label-position="outside"
                        placeholder="Enter maximum samples value"
                        value={`${variableData?.max_samples}`}
                        readOnly={true}
                    />
                </div>
                <div className='tds-col-sm-6'>
                    <TdsTextField
                        type="number"
                        size="md"
                        state="default"
                        modeVariant="secondary"
                        label="Sample interval ms"
                        placeholder="Enter sample interval value in ms"
                        label-position="outside"
                        value={`${variableData?.sample_interval_ms}`}
                        readOnly={true}
                    />
                </div>
            </div>
        </>
    );
}

export default observer(TimeSeriesView)