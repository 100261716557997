/* eslint-disable */
import React from 'react';

type ArrowDownProps = {
  show: boolean
};

export const ArrowDown = ({ show }: ArrowDownProps) => {
  return <> {show && <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.13668 4.89156C2.33194 4.6963 2.64853 4.6963 2.84379 4.89156L7.78786 9.83563C7.90501 9.95278 8.09496 9.95278 8.21212 9.83563L13.1562 4.89156C13.3515 4.6963 13.668 4.6963 13.8633 4.89157C14.0586 5.08683 14.0586 5.40341 13.8633 5.59867L8.91923 10.5427C8.41155 11.0504 7.58843 11.0504 7.08075 10.5427L2.13668 5.59867C1.94142 5.40341 1.94142 5.08683 2.13668 4.89156Z" fill="#0D0F13" />
  </svg>}
  </>
}

export default ArrowDown;
