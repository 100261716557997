import { TdsTextField } from '@scania/tegel-react';
import { persistDropdownList } from '../../../helpers/genericDropdownList';
import { isNotUpdateable } from '../../../helpers/isNotUpdateable';
import { observer } from 'mobx-react';
import GenericDropdown from '../../GenericDropdown/GenericDropdown';
import './Gauge.scss';
import TextField from '../../TextField/TextField';

interface GaugeProps {
    variableData?: VariableDetail
    mode: string
    isUpdateWithVersion: boolean
}
const Gauge = ({ variableData, mode, isUpdateWithVersion }: GaugeProps) => {
    return (<>
        <div className='tds-row tds-u-mt3'>
            <div className='tds-col-sm-4'>
                <TextField
                    label="Unit *"
                    labelPosition="outside"
                    size="md"
                    required={true}
                    dataFieldName='unit'
                    placeholder="Enter the unit of measurement"
                    readOnly={isNotUpdateable(mode, 'Unit', isUpdateWithVersion)}
                    modeVariant='secondary'
                />
            </div>
            <div className='tds-col-sm-4'>
                {isNotUpdateable(mode, 'Persist', isUpdateWithVersion) ?
                    <TdsTextField
                        type="text"
                        size="md"
                        state="default"
                        modeVariant="secondary"
                        label="Persist *"
                        labelPosition="outside"
                        readOnly={true}
                        value={variableData?.persist === '1' ? "True" : "False"}>
                    </TdsTextField>
                    :
                    <GenericDropdown
                        label="Persist *"
                        placeholder="Select persist value"
                        size="md"
                        labelPosition="outside"
                        modeVariant="secondary"
                        listData={persistDropdownList}
                        dataFieldName='persist'
                        required={true}
                    />

                }
            </div>
            {/* TODO: There is no key in the API response for value type right now. to be updated later */}
            <div className='tds-col-sm-4'>
                <TextField
                    label="Value type *"
                    size="md"
                    placeholder="Enter value type"
                    required={true}
                    label-position="outside"
                    modeVariant="secondary"
                    readOnly={isNotUpdateable(mode, 'Value type', isUpdateWithVersion)}
                    dataFieldName='value_type'
                />
            </div>
        </div>
    </>
    );
};

export default observer(Gauge);