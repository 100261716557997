
const variableTypesList = [
    { name: "Gauge", value: "gauge" },
    { name: "Histogram (Class)", value: "class" },
    { name: "Trigger", value: "trigger" },
    { name: "Time series", value: "time_series" }
]

const modelDropdownList = [
    { name: "BOTH", value: "BOTH" },
    { name: "HIGH", value: "HIGH" },
    { name: "LOW", value: "LOW" },
    { name: "ON_CHANGE", value: "ON_CHANGE" },
    { name: "TIMER", value: "TIMER" }
]


const persistDropdownList = [
    { name: "False", value: "0" },
    { name: "True", value: "1" }
]

const shouldRemoveRedundatSamplesDropdownList = [
    { name: "False", value: "false" },
    { name: "True", value: "true" }
]

const scopeValues = {
    gauge: "101",
    trigger: "102",
    class: "103",
    time_series: "104"
}

export {
    variableTypesList,
    modelDropdownList,
    persistDropdownList,
    shouldRemoveRedundatSamplesDropdownList,
    scopeValues
};