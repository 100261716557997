import { TdsButton } from "@scania/tegel-react"
import { useUserRole } from "../../../hooks/useUserRole"
import { useNavigate } from "react-router-dom";

const CreateButton = () => {
  const { isEditor } = useUserRole();
  const navigate = useNavigate();

  const handleCreateBtnClick = () => {
    navigate(`/create`);

  }
  return <>
    <div className="vertical-center edit-mode-message">
      <p>Select a variable from the list to view details {isEditor ? "or create a new variable" : "."}</p>
      {isEditor && <TdsButton className='tds-u-mt2' onClick={handleCreateBtnClick} variant="primary" size="lg" text="Create"></TdsButton>}
    </div>

  </>

}
export default CreateButton