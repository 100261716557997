import { TdsTextarea } from "@scania/tegel-react";
import { Field, useForm } from "react-final-form";
import { debounce } from "lodash";
import type { JSX } from '@scania/tegel';

import { requiredValidator, regexValidator, composeValidators } from '../../helpers/validationHelper'
interface TextAreaFieldPropType extends JSX.TdsTextarea {
  onValueChange?: (newtValue: string) => void
  dataFieldName: string
  required?: boolean
  pattren?: string | RegExp
  pattrenErrorMessage?: string
}

const TextArea = (props: TextAreaFieldPropType) => {
  const { value, dataFieldName,
    required, pattren, pattrenErrorMessage 
  } = props
  const { isValidationPaused } = useForm();
  const pattrenValidator = (value: string) => {
    if (!isValidationPaused() && pattren) {
      return regexValidator(pattren, pattrenErrorMessage as string)(value);
    }
    return "";
  }
  const requiredFieldValidator = (value: string) => {
    if (!isValidationPaused() && required) {
      return requiredValidator(dataFieldName,value);
    }
    return "";
  }
  
  return <Field name={dataFieldName} initialValue={value} validate={composeValidators(requiredFieldValidator, pattrenValidator)}>
    {({ input, meta }) => (
      <TdsTextarea {...props}
        name={input.name}
        onTdsInput={debounce(input.onChange, 300)}
        helper={meta.touched && (meta.error || meta.invalid) ? meta.error : ""}
        state={(meta.touched && (meta.error || meta.invalid)) ? "error" : "default"}
        value={!input.value ? "" : input.value}
        labelPosition={props.labelPosition || 'outside'}
      />)}
  </Field>
}

export default TextArea
