import { createContext } from 'react';

export const UserActionContext = createContext<ActionContextType>({
    action: 'read',
    setAction: () => { },
    updateWithVersion: false,
    setUpdateWithVersion: () => { },
    variableStatus: '',
    setVariableStatus: () => { }
});
