import axios, { AxiosRequestConfig } from 'axios';
import { msalInstance,tokenRequest } from '../authConfig';

const variableRegistryApi = axios.create({
  baseURL: process.env.REACT_APP_API_BASE,
  timeout: 60000,
  headers: {
    'Content-Type': 'application/json;charset=utf-8'
  },
});

variableRegistryApi.interceptors.request.use(async (config: AxiosRequestConfig) => {
  try {
    const accounts = msalInstance.getAllAccounts() as any;
    const account = accounts[0];
    msalInstance.setActiveAccount(account);
    const response = await msalInstance.acquireTokenSilent({
      ...tokenRequest,
      account,
    });
    const bearer = `Bearer ${response.accessToken}`;
    config.headers = { Authorization: bearer };

  } catch (error) {
    console.log(error)
  }
  return config;
});

export default variableRegistryApi
