import { observable, makeObservable, action } from "mobx"
import { callMsGraphUserPhoto } from "../adapters/MsGraphApiCall";
import { msalInstance } from "../authConfig";

export class UserProfileStore {

  userProfile: GraphData | null = null;
  userPhoto: Blob | null = null;
  roles: Array<string> | undefined;

  constructor() {
    this.roles = undefined
    return makeObservable(this, {
      userProfile: observable,
      userPhoto: observable,
      roles: observable,
      fetchUserProfile: action
    });
  }

  fetchUserProfile = () => {
    callMsGraphUserPhoto().then(action("graphSuccess", (photoBlob) => {
      if (photoBlob) {
        this.userPhoto = photoBlob;
      }
    })).catch((error: Error) => {
      console.log("Error occurred while requesting User Picture ", error);
    });

    const activeAccount = msalInstance.getActiveAccount();
    this.roles = activeAccount?.idTokenClaims?.roles;
    this.userProfile = { displayName: activeAccount?.name ? activeAccount?.name : '', mail: activeAccount?.idTokenClaims?.preferred_username ? activeAccount?.idTokenClaims?.preferred_username  : '' }
  }

}

export default UserProfileStore