import { TdsTextField } from '@scania/tegel-react';
import { observer } from 'mobx-react';
import './GuageView.scss';

interface GaugeViewProps {
    variableData?: VariableDetail
}

const GaugeView = ({ variableData }: GaugeViewProps) => {
    return (<>
        <div className='tds-row tds-u-mt3'>
            <div className='tds-col-sm-4'>
                <TdsTextField
                    type="text"
                    label="Unit *"
                    labelPosition="outside"
                    size="md"
                    placeholder="Enter the unit of measurement"
                    readOnly={true}
                    modeVariant='secondary'
                    value={variableData?.unit}
                />
            </div>
            <div className='tds-col-sm-4'>
                <TdsTextField
                    type="text"
                    size="md"
                    state="default"
                    modeVariant="secondary"
                    label="Persist *"
                    labelPosition="outside"
                    readOnly={true}
                    value={variableData?.persist === '1' ? "True" : "False"}>
                </TdsTextField>
            </div>
            {/* TODO: There is no key in the API response for value type right now. to be updated later */}
            <div className='tds-col-sm-4'>
                <TdsTextField
                    type='text'
                    label="Value type *"
                    state="default"
                    size="md"
                    placeholder="Enter value type"
                    label-position="outside"
                    modeVariant="secondary"
                    readOnly={true}
                    value={variableData?.value_type}
                />
            </div>
        </div>
    </>
    );
};

export default observer(GaugeView);