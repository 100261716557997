import React from 'react'
import './ValueNamesEditBox.scss'
import { TdsButton } from '@scania/tegel-react';
import TextField from '../../TextField/TextField';

type ValueNamesEditBoxPropsType = {
   index: number,
   removeFunction: Function,
   name:string
}

const ValueNamesEditBox = ({ index, removeFunction,name }: ValueNamesEditBoxPropsType) => {

   const notAHexCodeErrorMsg = 'Code should be a hexadecimal value';
 
   const hexRegex = "^0[xX][0-9A-Fa-f]+$";
   return (
      <div className="tds-background-grey-200 tds-u-mt2 tds-u-pb2">
         <div className='tds-container-fluid tds-u-p1'>
            <div className='tds-row'>
               <div className="tds-col-sm-12 tds-u-mt1 tds-u-flex-end">
                  <TdsButton variant="danger" size="xs" text="Remove" onClick={()=>removeFunction(index)} />
               </div>
            </div>

            <div className="tds-row tds-u-mt2">
               <div className="tds-col-sm-6">
                  <TextField
                     label='Code (Hexadecimal)*'
                     labelPosition='outside'
                     dataFieldName={`${name}.hexCode`}
                     required={true}
                     pattren={hexRegex}
                     pattrenErrorMessage={notAHexCodeErrorMsg}
                     placeholder="Enter code in Hexadecimal format e.g. 0x1A"
                     size='md'
                  />
               </div>
               <div className="tds-col-sm-6">
                  <TextField
                     label='Name*'
                     dataFieldName={`${name}.name`}
                     required={true}
                     placeholder="Enter name"
                     size='md'
                  />
               </div>
            </div>
         </div>
      </div>
   )
}

export default ValueNamesEditBox