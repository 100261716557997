import React from 'react'
import Header from '../components/Header/Header' 
import './Layout.scss'
import { Outlet } from 'react-router-dom'
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react'
import UnAuthenticated from './UnAuthenticated'
import ToastContainer from '../components/Toast/ToastContainer'


const Layout = () => {

  return (
    <div className="layout">
      <Header /> {/* Header */}
      {/* main */}
      <main className="main">
        <AuthenticatedTemplate>
          <Outlet />
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <UnAuthenticated />
        </UnauthenticatedTemplate>
        <ToastContainer />
      </main>
    </div>
  )
}

export default Layout