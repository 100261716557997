
import { TdsTextField } from '@scania/tegel-react';
import { modelDropdownList, persistDropdownList } from '../../../helpers/genericDropdownList';
import { getDisplayValueForTextFields } from '../../../helpers/getDisplayValue';
import { isNotUpdateable } from '../../../helpers/isNotUpdateable';
import { observer } from 'mobx-react';
import GenericDropdown from '../../GenericDropdown/GenericDropdown';
import './Trigger.scss';
import TextField from '../../TextField/TextField';
import { useFormState } from 'react-final-form';

interface TriggerProps {
    variableData?: VariableDetail
    mode: string
    isUpdateWithVersion: boolean
}
const Trigger = ({ variableData,  mode, isUpdateWithVersion }: TriggerProps) => {
    const { values } = useFormState();
    const getValues = (dataFieldname: string) => {
        return values[dataFieldname];
    }

    return (
        <>
            <div className="tds-row tds-u-mt2">
                <div className="tds-col-sm-4 tds-col-xs-4">
                    <TextField
                        label="Unit"
                        size="md"
                        modeVariant='secondary'
                        label-position="outside"
                        value={getDisplayValueForTextFields(variableData?.unit, mode)}
                        readOnly={isNotUpdateable(mode, 'Unit', isUpdateWithVersion)}
                        placeholder='Enter unit of measurement'
                        dataFieldName="unit"
                    />
                </div>
                <div className="tds-col-sm-4 tds-col-xs-4">
                    {/* //TODO: Currently, the API does not provide any data or at least an attribute. Once the API data is received, it needs to be implemented properly */}
                    {isNotUpdateable(mode, 'Mode', isUpdateWithVersion) ?
                        <TdsTextField
                            type="text"
                            size="md"
                            state="default"
                            modeVariant="secondary"
                            label="Mode *"
                            labelPosition="outside"
                            readOnly={true}
                            value={getDisplayValueForTextFields(variableData?.mode, mode)}>
                        </TdsTextField>
                        : <GenericDropdown
                            label="Mode *"
                            labelPosition="outside"
                            placeholder="Select mode"
                            size="md"
                            modeVariant="secondary"
                            openDirection="down"
                            defaultValue={getValues("mode")}
                            required={true}
                            listData={modelDropdownList}
                            dataFieldName='mode'
                        />
                    }
                </div>
                <div className="tds-col-sm-4 tds-col-xs-4">
                    {isNotUpdateable(mode, 'Persist', isUpdateWithVersion) ?
                        <TdsTextField
                            type="text"
                            size="md"
                            state="default"
                            modeVariant="secondary"
                            label="Persist"
                            labelPosition="outside"
                            readOnly={true}
                            value={variableData?.persist === '1' ? "True" : "False"}>
                        </TdsTextField>
                        : <GenericDropdown
                            label="Persist"
                            labelPosition="outside"
                            placeholder="Select a persist value"
                            size="md"
                            modeVariant="secondary"
                            openDirection="down"
                            listData={persistDropdownList}
                            dataFieldName='persist'
                        />
                    }
                </div>
            </div>
        </>
    );
};

export default observer(Trigger);