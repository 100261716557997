import React, { useState } from 'react';
import { ArrowDown } from '../../assets';
import './DropdownButton.scss';

type ListItem = {
  name: string;
  value: string;
};

interface DropdownProps {
  label: string;
  options: Array<ListItem>;
  onSelect: (value: string) => void;
  buttonColor: string;
  behavior?: "submit" | "button"
}

const DropdownButton = ({ label, options, onSelect, buttonColor, behavior }: DropdownProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleMouseEnter = () => {
    setIsOpen(true);
  }
  const handleMouseLeave = () => {
    setIsOpen(false);
  }
  const handleClick = (value: string) => {
    onSelect(value);
    setIsOpen(false);
  };

  return (
    <div
      className="dropdown"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className={`dropdown-button ${buttonColor}`}>
        {label} <ArrowDown show={true} />
      </div>

      <div className="dropdown-menu" style={{ "display": (isOpen ? "block" : "none") }}>
        {options.map((option) => (
          <button type={behavior || "button"} key={option.name} onClick={() => handleClick(option.value)} name='userAction' value={option.value}>
            {option.name}
          </button>
        ))}
      </div>

    </div>
  )
}

export default DropdownButton;
