import React from 'react'
import { TdsButton, TdsIcon } from '@scania/tegel-react';
import ValueNamesEditBox from './ValueNamesEditBox/ValueNamesEditBox';
import { observer } from 'mobx-react';
import { FieldArray, FieldArrayRenderProps } from 'react-final-form-arrays';

import './ValueNamesEdit.scss'

const ValueNamesEdit = () => {
   const valueNameFormItemKey = "value_names"

   return <>
      <FieldArray name={valueNameFormItemKey}>
         {({ fields }: FieldArrayRenderProps<any, HTMLElement>) => <>
            <div className='tds-u-flex-end'>
               <TdsButton variant="primary" size="sm" text="Add" onClick={() => fields.push(null)}>
                  <TdsIcon slot="icon" size="20px" name="plus" />
               </TdsButton>
            </div>
            {fields && fields.map((name, index) => {
               return <ValueNamesEditBox
                  key={"value-name-box-" + index}
                  index={index}
                  removeFunction={() => { fields.remove(index) }}
                  name={name}
               />
            })}
            {!fields && <div className='tds-container-fluid tds-u-p1'>
               <div className='tds-row'>
                  <div className="tds-col-md-12 tds-u-mt1">
                     Click the add button to start adding value names.
                  </div>
               </div>
            </div>}
         </>}
      </FieldArray>
   </>
}

export default observer(ValueNamesEdit)