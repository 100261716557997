import { useContext } from "react"
import { TdsTextarea, TdsTextField } from "@scania/tegel-react"
import "./GenericFieldsView.scss"
import { UserActionContext } from "../../../AppContext"
import { getDisplayValueForTextFields } from "../../../helpers/getDisplayValue"
import { observer } from "mobx-react"

type GenericFieldsViewProps = {
    selectedVariableDetails?: VariableDetail
}

const GenericFieldsView = ({ selectedVariableDetails }: GenericFieldsViewProps) => {
    const { action } = useContext(UserActionContext);

    return <>
        <div className='tds-row'>
            <div className="tds-col-sm-6">
                <TdsTextField
                    type="text"
                    size="md"
                    state="default"
                    modeVariant="secondary"
                    label="Variable type *"
                    labelPosition="outside"
                    readOnly={true}
                    value={getDisplayValueForTextFields(selectedVariableDetails?.type, action)}
                >
                </TdsTextField>
            </div>
            <div className="tds-col-sm-6">
                <TdsTextField
                    type="text"
                    size="md"
                    state="default"
                    modeVariant="secondary"
                    placeholder="Enter the minimum CDC version required"
                    label="Minimum CDC version"
                    labelPosition="outside"
                    readOnly={true}
                    value={getDisplayValueForTextFields(selectedVariableDetails?.minimum_cdc_version, action)}
                />
            </div>
        </div>
        <div className="tds-row tds-u-mt3">
            <div className="tds-col-sm-12">
                <TdsTextField
                    type="text"
                    size="md"
                    modeVariant="secondary"
                    placeholder="Enter a name "
                    label="Variable name *"
                    labelPosition="outside"
                    readOnly={true}
                    value={getDisplayValueForTextFields(selectedVariableDetails?.name, action)}
                />
            </div>
        </div>
        <div className="tds-row tds-u-mt3">
            <div className="tds-col-sm-6">
                <TdsTextField
                    type="text"
                    size="md"
                    modeVariant="secondary"
                    placeholder="Enter current status name for the variable"
                    label="Current status name"
                    labelPosition="outside"
                    readOnly={true}
                    value={getDisplayValueForTextFields(selectedVariableDetails?.current_status_name, action)}
                />
            </div>
            <div className="tds-col-sm-6">
                <TdsTextField
                    type="text"
                    size="md"
                    modeVariant="secondary"
                    placeholder="Enter RFMS name for the variable"
                    label="RFMS name"
                    labelPosition="outside"
                    readOnly={true}
                    value={getDisplayValueForTextFields(selectedVariableDetails?.rfms_name, action)} />
            </div>
        </div>
        <div className="tds-row tds-u-mt3">
            <div className="tds-col-sm-12">
                <TdsTextarea
                    state="default"
                    rows={2}
                    modeVariant="secondary"
                    placeholder="Enter a description for the variable you are creating"
                    label="Description"
                    labelPosition="outside"
                    readOnly={true}
                    value={getDisplayValueForTextFields(selectedVariableDetails?.description, action)}
                >
                </TdsTextarea>
            </div>
        </div>
    </>
}

export default observer(GenericFieldsView)