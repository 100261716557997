import React from 'react';
import { TdsButton } from '@scania/tegel-react';
import TypeDropDown from './TypeDropDown';
import { ChainEditPropType } from './types';
import TextField from '../../TextField/TextField';

const TypeAcking = ({ chainItem, onRemoveChainItemClick, onItemTypeChange, onAttributeValueChange, index,name }: ChainEditPropType) => {
  return <div className='tds-container-fluid tds-u-p1'>
    <div className='tds-row'>
      <div className="tds-col-md-12 tds-u-mt1 tds-u-flex-end">
        <TdsButton text='Remove' variant='danger' size='xs' onClick={onRemoveChainItemClick} />
      </div>
    </div>
    <div className="tds-row ">
      <div className="tds-col-md-3">
        <TypeDropDown onItemTypeChange={onItemTypeChange} dataFieldName={name} />
      </div>
      <div className="tds-col-md-3">
        <TextField 
          label='Value Mask' 
          value={chainItem?.value_mask} 
          labelPosition="outside" 
          dataFieldName={`${name}.value_mask`}
        />
      </div>
      <div className="tds-col-md-6">
        <TextField 
          label='Address' 
          value={chainItem?.address} 
          labelPosition="outside" 
          dataFieldName={`${name}.address`}
        />
      </div>
    </div>
    <div className="tds-row tds-u-mt2">
      <div className="tds-col-md-3">
      <TextField 
          label='Frame Template' 
          value={chainItem?.frame_template} 
          labelPosition="outside" 
          dataFieldName={`${name}.frame_template`}
        />
      </div>
      <div className="tds-col-md-3">
        <TextField 
          label='Include Value at Offset' 
          value={chainItem?.include_value_at_offset} 
          labelPosition="outside" 
          dataFieldName={`${name}.include_value_at_offset`}
        />     
      </div>
    </div>
  </div>;
}

export default TypeAcking