import React from 'react';
import { TdsBodyCell, TdsTableBodyRow } from '@scania/tegel-react';
import VariableTypeIcon from '../../VariableTypeIcon/VariableTypeIcon';
import VariableVersionIcon from '../../VariableVersionIcon/VariableVersionIcon';

type ViewDefinitionRowProps = {
    id: number,
    name: string,
    version: string,
    type?: string,
    showDetails: () => void;
    //isLoading: boolean;
    //selectedItem: number | null
    // description?: string,
    // status?: string
}
const VariableListRow = (props: ViewDefinitionRowProps) => {
    const { id, showDetails, name, version, type } = props;

    return (
        <TdsTableBodyRow onClick={showDetails} onKeyDown={showDetails} role="button">
            <TdsBodyCell cellKey="id" className='id-column'>{id}</TdsBodyCell>
            <TdsBodyCell cellKey="vName" className='name-column' title={name}>{name}</TdsBodyCell>
            <TdsBodyCell cellKey="version" className='version-column'>
                <VariableVersionIcon version={version}></VariableVersionIcon>
            </TdsBodyCell>
            {/* The icon needs to be changed based on the type when received from the API  */}
            <TdsBodyCell cellKey="vType" className='type-column'>
                {type && type?.toLowerCase() === "gauge" && <VariableTypeIcon type='G' tooltipText="Gauge" key={`G-${id}`}></VariableTypeIcon>}
                {type && type?.toLowerCase() === "class" && <VariableTypeIcon type='H' tooltipText="Histogram" key={`H-${id}`}></VariableTypeIcon>}
                {type && type?.toLowerCase() === "time_series" && <VariableTypeIcon type='Ts' tooltipText="Time Series" key={`Ts-${id}`}></VariableTypeIcon>}
                {type && type?.toLowerCase() === "trigger" && <VariableTypeIcon type='Tr' tooltipText="Trigger" key={`Tr-${id}`}></VariableTypeIcon>}
                {!type && <VariableTypeIcon type='-' tooltipText="Type is not available" key={`NA-${id}`}></VariableTypeIcon>}

            </TdsBodyCell>
        </TdsTableBodyRow>
    )
}
export default VariableListRow;