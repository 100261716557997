import { ALL_FIELDS_ARRAY } from "./constants";

interface AttributesMap {
  [index: string]: Array<string>
}
const requiredAttributesMap: AttributesMap = {
  "gauge": ["type", "name", "unit", "value_type", "chain", "persist"],
  "class": ["type", "name", "unit", "boundaries", "chain"],// Histogram
  "trigger": ["type", "name", "mode", "chain"],
  "time_series": ["type", "name", "persist", "max_samples", "chain"]
}

export const validateRequiredAttributes = (variableData: VariableDetail, requiredAttributes: Array<string>): Array<string> => {
  const invalidFields: Array<string> = [];
  requiredAttributes && requiredAttributes.forEach((attribute: string) => {
    if (attribute === "chain") {
      if (variableData.chain && variableData.chain.length > 0) {
        variableData.chain.forEach((chainItem: ChainItemType) => {
          if (chainItem.type === "" || chainItem.input_signal === "" || chainItem.input_variable === "") {
            invalidFields.push(attribute)
          }
        })
      } else {
        invalidFields.push(attribute)
      }
    } else if (attribute === 'value_names') {
      const valueNamesAsArray = variableData.value_names as Array<ValueNamesObjectType>
      if (valueNamesAsArray && valueNamesAsArray.length > 0) {
        valueNamesAsArray.forEach((valueNameItem: ValueNamesObjectType) => {
          if (valueNameItem.hexCode === "" || valueNameItem.name === "") {
            invalidFields.push(attribute)
          }
        })
      } else {
        invalidFields.push(attribute)
      }

    } else {
      if (variableData[attribute] === undefined || variableData[attribute] === "") { invalidFields.push(attribute); }
    }
  })
  return invalidFields
}
export const requiredValidator = (dataFieldName: string, value: string) => {
  const name = dataFieldName.split(".").pop();
  if (!value)
    return `${name ? name.toPascalCase() : dataFieldName} is required!`

  return undefined;
}

export const regexValidator = (pattren: string | RegExp, pattrenMisMatchMessage: string) => (value: string) => {
  const pattrenRegex = new RegExp(pattren);
  return !pattrenRegex.test(value) ? pattrenMisMatchMessage : undefined
}

export const mustBeNumber = (value: any) => (isNaN(value) ? 'Must be a number' : undefined)

export const minValue = (min: any) => (value: any) =>
  isNaN(value) || value >= min ? undefined : `Should be greater than ${min}`

export const composeValidators = (...validators: any) => (value: any) =>
  validators.reduce((error: any, validator: any) => error || validator(value), undefined)

export const validateVariable = (variableData: VariableDetail) => {
  return validateRequiredAttributes(variableData, requiredAttributesMap[variableData.type])
}

export const resetFormValidations = (resetFieldState: (name: string) => void) => {
  ALL_FIELDS_ARRAY.forEach((fieldName: string) => {
    if(!["chain","value_names"].includes(fieldName) ){
      resetFieldState(fieldName)
    }
  });
}

export default validateVariable;
