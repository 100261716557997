import React, { memo } from 'react';
import './Spinner.scss';
import { TdsSpinner } from '@scania/tegel-react';

interface SpinnerProps {
  size?: "xs" | "sm" | "md" | "lg",
  value?: number
}
const Spinner = ({ size, value }: SpinnerProps) => {
  return (
    <div className='spinner'>
      <TdsSpinner  size={!size ? "md" : size}>
        {
          value !== undefined && value !== null &&  (<span className='percentage'>{value}%</span>)
        }
      </TdsSpinner>
    </div>
  );
};

export default memo(Spinner);