
import React from 'react';
import TypeInput from './components/TypeInput';
import TypeDyadic from './components/TypeDyadic';
import TypeFilter from './components/TypeFilter';
import TypeNone from './components/TypeOnly';
import TypeComparator from './components/TypeComparator';
import TypePolynomial from './components/TypePolynomial';
import TypeWork from './components/TypeWork';
import TypeAccumulating from './components/TypeAccumulating';
import TypeCast from './components/TypeCast';
import TypeTrailerIdentification from './components/TypeTrailerIdentification';
import TypeAcking from './components/TypeAcking';
import TypeWindowedAverage from './components/TypeWindowedAverage';
import TypeIndex from './components/TypeIndex';
import TypeSignals from './components/TypeSignals';
import { ChainItemTypeEnum } from '../../helpers/dataHelper';



interface ChainEditPropType {
  chainItem?: ChainItemType
  onRemoveChainItemClick: () => void
  onItemTypeChange?: (newType: ChainItemTypeEnum) => void
  onAttributeValueChange?: (attribute: string, newValue: any) => void
  index: number
  name:string
}

const ChainComponent = (props: ChainEditPropType) => {
  const renderSpecificComponent = (props: ChainEditPropType): any => {
    switch (props.chainItem?.type) {
      case ChainItemTypeEnum.AbsoluteCount:
        return <TypeNone {...props} />

      case ChainItemTypeEnum.Accumulating:
        return <TypeAccumulating  {...props} />

      case ChainItemTypeEnum.AccumulatingSelf:
        return <TypeNone {...props} />

      case ChainItemTypeEnum.AccumulatingTime:
        return <TypeNone {...props} />

      case ChainItemTypeEnum.Acking:
        return <TypeAcking  {...props} />

      case ChainItemTypeEnum.Age:
        return <TypeNone {...props} />

      case ChainItemTypeEnum.Comparator:
        return <TypeComparator {...props} />

      case ChainItemTypeEnum.Differential:
        return <TypeNone {...props} />

      case ChainItemTypeEnum.Dyadic:
        return <TypeDyadic {...props} />

      case ChainItemTypeEnum.Filter:
        return <TypeFilter {...props} />

      case ChainItemTypeEnum.Index:
        return <TypeIndex  {...props} />

      case ChainItemTypeEnum.Input:
        return <TypeInput {...props} />

      case ChainItemTypeEnum.LowPass:
        return <TypeNone {...props} />

      case ChainItemTypeEnum.Map:
        return <TypeNone  {...props} />

      case ChainItemTypeEnum.Not:
        return <TypeNone {...props} />

      case ChainItemTypeEnum.Polynomial:
        return <TypePolynomial {...props} />

      case ChainItemTypeEnum.Signals:
        return <TypeSignals  {...props} />

      case ChainItemTypeEnum.TrailerIdentification:
        return <TypeTrailerIdentification  {...props} />

      case ChainItemTypeEnum.TypeCast:
        return <TypeCast  {...props} />

      case ChainItemTypeEnum.WindowedAverage:
        return <TypeWindowedAverage  {...props} />

      case ChainItemTypeEnum.Work:
        return <TypeWork  {...props} />

      default:
        return <TypeNone {...props} />

    }
  }
  return <div className='tds-background-grey-200 tds-u-mt2 tds-u-pb2'>
    {renderSpecificComponent(props)}
  </div>;
}

export default ChainComponent