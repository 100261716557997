import { observer } from "mobx-react";
import { TdsAccordion, TdsAccordionItem } from "@scania/tegel-react";
import ValueNamesDisplay from "../../ValueNamesDisplay/ValueNamesDisplay";
import ChainDisplay from "../../ChainDisplay/ChainDisplay";
import GenericFieldsView from "../GenericFieldsView/GenericFieldsView";
import './FormSectionView.scss'
import GuageView from "../GuageView/GuageView";
import TriggerView from "../TriggerView/TriggerView";
import HistogramView from "../HistogramView/HistogramView";
import TimeSeriesView from "../TimeSeriesView/TimeSeriesView";
import { VARIABLE_TYPES }  from "../../../helpers/constants";

type FormSectionViewProps = {
  selectedVariableDetails?: VariableDetail | undefined
}

const FormSectionView = ({ selectedVariableDetails }: FormSectionViewProps) => {
  return <div className={`tds-container-fluid tds-background-grey-50 tds-u-p1 tds-u-pt2 tds-u-mt1 form-container read`}>
    <GenericFieldsView selectedVariableDetails={selectedVariableDetails} />
    {selectedVariableDetails?.type === VARIABLE_TYPES.GAUGE && <GuageView variableData={selectedVariableDetails} ></GuageView>}
    {selectedVariableDetails?.type === VARIABLE_TYPES.TRIGGER && <TriggerView variableData={selectedVariableDetails} ></TriggerView>}
    {selectedVariableDetails?.type === VARIABLE_TYPES.HISTOGRAM && <HistogramView variableData={selectedVariableDetails} ></HistogramView>}
    {selectedVariableDetails?.type === VARIABLE_TYPES.TIME_SERIES && <TimeSeriesView variableData={selectedVariableDetails}></TimeSeriesView>}
    {!selectedVariableDetails?.type && <div>Variable type information missing</div>}

    {selectedVariableDetails &&
      <TdsAccordion className="tds-u-mt3">
        {selectedVariableDetails?.type === VARIABLE_TYPES.GAUGE &&
          <TdsAccordionItem header="Value names" expanded paddingReset >
            <ValueNamesDisplay valueNamesObject={selectedVariableDetails?.value_names} />
          </TdsAccordionItem>}
        <TdsAccordionItem header="Chain *" expanded paddingReset>
          <ChainDisplay chainData={selectedVariableDetails?.chain} />
        </TdsAccordionItem>
      </TdsAccordion>}
  </div>
}

export default observer(FormSectionView)
