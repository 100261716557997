import React from "react";
import { TdsButton, TdsCheckbox, TdsModal, TdsRadioButton } from "@scania/tegel-react";
import VariableTypeIcon from "../../VariableTypeIcon/VariableTypeIcon";

import './VariablesFilterModal.scss';
import {VariableListStore, VariableStateFilter, VariableTypeFilter } from "../../../stores/variable-list-store";
import { observer } from "mobx-react";

interface VariableFilterModelParamType {
    store: VariableListStore
}
const VariablesFilterModal = ({ store }: VariableFilterModelParamType) => {

    const onTypeFilterChange = (e: TdsCheckboxCustomEvent<CheckboxChangeEvent>) => {
        store.onChangeVariableTypeFilter(e.detail.value, e.detail.checked);
    }

    const onStateFilterChange = (e: TdsCheckboxCustomEvent<CheckboxChangeEvent>) => {
        store.onChangeVariableStateFilter(e.detail.value, e.detail.checked);
    }

    const onVersionTypeChange = (e: TdsRadioButtonCustomEvent<RadioButtonChangeEvent>) => {
        store.onChangeVersionFilter(e.detail.value)
    }

    const submitValuesForFiltering = () => {
        store.onSubmitFilters();
    }

    const onResetFilters = () => {
        store.onResetFilters();
    }

    const onCancelFilters = () => {
        store.onCancelFilters();
    }

    return (
        <TdsModal
            header="Filter variable list"
            selector="#filter-variable-list"
            id="filter-variable-list"
            size="xs"
            actions-position="static"
            className="filterModal"
            prevent
        >
            <span slot="body">

                <div className="row">
                    <div className="variableTypesFilterList">
                        <h5 className="tds-headline-05 title">Variable types</h5>
                        {Object.values(store.variableTypesList).map((item: VariableTypeFilter) => (
                            <TdsCheckbox key={`typesList-id-${item.name}`} checkboxId={`typesList-id-${item.name}`} value={item.name} checked={item.checked} className="filterRow" onTdsChange={onTypeFilterChange}>
                                <div slot="label" className="filterLabel tds-u-gap1">
                                    {item.name}
                                    <VariableTypeIcon type={item.iconType}></VariableTypeIcon>
                                </div>
                            </TdsCheckbox>
                        ))}
                    </div>

                    <div className="variableStatesFilterList">
                        <h5 className="tds-headline-05 title">Variable state</h5>
                        {Object.values(store.variableStatesList).map((item: VariableStateFilter) => (
                            <TdsCheckbox key={`stateList-id-${item.name}`} checkboxId={`stateList-id-${item.name}`} value={item.name} checked={item.checked} className="filterRow" onTdsChange={onStateFilterChange}>
                                <div slot="label" className="filterLabel">
                                    {item.name}
                                </div>
                            </TdsCheckbox>
                        ))}
                    </div>
                </div>
                <div className="tds-u-mt3">
                    <h5 className="tds-headline-05 title">Variable version</h5>
                    <fieldset className="selectVersionButtonsFieldset">
                        <TdsRadioButton name="version-filter" value="all" radioId="select-all" checked={store.selectedFilterVersion === "all"} onTdsChange={onVersionTypeChange} >
                            <div slot="label"> Show all versions </div>
                        </TdsRadioButton>
                        <TdsRadioButton name="version-filter" value="latest" radioId="select-latest" checked={store.selectedFilterVersion === "latest"} onTdsChange={onVersionTypeChange}>
                            <div slot="label"> Show only latest version </div>
                        </TdsRadioButton>
                    </fieldset>
                </div>
            </span>
            <span slot="actions" className="tds-u-flex tds-u-gap2">
                <TdsButton data-dismiss-modal size="sm" text="Cancel" variant="secondary" onClick={onCancelFilters}></TdsButton>
                <TdsButton size="sm" text="Reset" variant="secondary" onClick={onResetFilters}></TdsButton>
                <TdsButton data-dismiss-modal type="submit" size="sm" text="Apply" variant="primary" onClick={submitValuesForFiltering}></TdsButton>
            </span>
        </TdsModal>
    );
};

export default observer(VariablesFilterModal);

