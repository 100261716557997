import { useEffect, useState } from "react";

import { useMsal } from "@azure/msal-react";
export const EDITOR_ROLE = "ScsCdcVariableEditor";
export const VIEWER_ROLE = "ScsCdcVariableViewer"

export const useUserRole = () => {

  const [userRole, setRole] = useState<string>("");
  const [isViewer, setIsViewer] = useState<boolean>(false);
  const [isEditor, setIsEditor] = useState<boolean>(false);

  const { instance } = useMsal()
  useEffect(() => {
    const activeAccount = instance.getActiveAccount();
    if (activeAccount && activeAccount.idTokenClaims?.roles) {
      if (activeAccount.idTokenClaims.roles.includes(VIEWER_ROLE)) {
        setRole(VIEWER_ROLE);
        setIsViewer(true)
      }
      if (activeAccount.idTokenClaims.roles.includes(EDITOR_ROLE)) {
        setRole(EDITOR_ROLE);
        setIsEditor(true)
      }
    }
  }, [instance]);
  return { userRole, isViewer, isEditor };
};