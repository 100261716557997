declare global {
  interface String {
    toPascalCase() : string;
  }
}
// eslint-disable-next-line no-extend-native
String.prototype.toPascalCase = function () {
  return this.toLowerCase().replace(/(?:^|\s)[a-z]/g, function (m: string) {
    return m.toUpperCase();
  });
}
export {}