
import { useLocation } from "react-router-dom";
import { VARIABLE_STATUS, VARIABLE_UPDATE_MODES } from "../../helpers/constants";
import DropdownButton from "../VariableDetails/DropdownButton/DropdownButton";
import { TdsButton } from "@scania/tegel-react";

interface TopSectionButtonsProps {
  variableStatus?: string,
  onUserAction: (userAction: string) => void
  returnToReadOnly: () => void
  onCloseClick: () => void
}
const TopSectionButtons = ({ variableStatus, onUserAction, returnToReadOnly, onCloseClick }: TopSectionButtonsProps) => {
  const { pathname } = useLocation();

  const isReadonly = pathname.toLowerCase().endsWith('view');
  const isEditMode = pathname.toLowerCase().endsWith('edit');

  return (
    <span className="tds-u-gap2 tds-row">
      {/* Published */}
      {isReadonly && variableStatus === VARIABLE_STATUS.PUBLISHED && <>
        <DropdownButton
          label="Select update mode"
          options={[{ name: "Update with version", value: VARIABLE_UPDATE_MODES.WithVersion }, { name: "Update w/o version", value: VARIABLE_UPDATE_MODES.WithoutVersion }]}
          onSelect={(option) => { onUserAction(option) }}
          buttonColor="mode"
        />
        <TdsButton type="submit" variant="danger" text="Deprecate " size="sm" onClick={() => { onUserAction('Deprecated'); }} />
      </>}
      {isEditMode && variableStatus === VARIABLE_STATUS.PUBLISHED && <>
        <TdsButton type="submit" variant="secondary" text="cancel " size="sm" onClick={() => { returnToReadOnly(); }} />
        {/* TODO: implement the save function on update with or w/o version  */}
        <TdsButton type="submit" variant="primary" text="Save" size="sm" onClick={() => { onUserAction('Save'); }} />
      </>}

      {/* Test */}
      {isReadonly && variableStatus === VARIABLE_STATUS.TEST && <>
        <TdsButton variant="secondary" text="Edit " size="sm" onClick={() => { onUserAction('edit') }} />
        <TdsButton type="submit" variant="primary" text="Publish" size="sm" onClick={() => { onUserAction('Published'); }} />
      </>}
      {isEditMode && variableStatus === VARIABLE_STATUS.TEST && <>
        <TdsButton variant="secondary" text="cancel " size="sm" onClick={() => { returnToReadOnly(); }} />
        <DropdownButton
          label="Select status"
          options={[{ name: "Save as draft", value: "Created" }, { name: "Publish", value: "Published" }]}
          onSelect={onUserAction}
          buttonColor="primary"
          behavior="submit" />
      </>}

      {/* Created */}
      {isReadonly && variableStatus === VARIABLE_STATUS.CREATED && <>
        <TdsButton
          variant="secondary"
          text="Edit"
          size="sm"
          onClick={() => {
            onUserAction('edit')
          }}
        />
        <TdsButton
          variant="primary"
          text="Move to test"
          size="sm"
          onClick={() => {
            onUserAction("Test");
          }}
        />
      </>}
      {isEditMode && variableStatus === VARIABLE_STATUS.CREATED && <>
        <TdsButton
          variant="secondary"
          text="cancel"
          size="sm"
          onClick={returnToReadOnly}
        />
        <DropdownButton
          label="Select status"
          options={[{ name: "Save as draft", value: "Created" }, { name: "Move to test", value: "Test" }]}
          onSelect={onUserAction}
          buttonColor="primary"
          behavior="submit"
        />
      </>}

    </span>
  )
}

export default TopSectionButtons