import { intersection, orderBy } from "lodash"
import { observable, action, computed, makeObservable } from "mobx"
import { compareVersions } from 'compare-versions';
import { getVariableList } from "../adapters/VariableDetailsAdapter"
import { RootStoreType } from ".";

export interface VariableType {
  id: number
  name: string
  version: string
  description?: null | string
  status: "Created" | "Published" | "Deprecated" | "Test" | string
  type: string
}

export interface VariableTypeFilter {
  name: string
  iconType: 'G' | 'H' | 'Ts' | 'Tr' | string
  checked: boolean
}
export interface VariableStateFilter {
  name: string
  checked: boolean
}

export class VariableListStore {
  variableList: Array<VariableType> = []
  filteredVariableList: Array<VariableType> = []
  selectedVariable: VariableType | null = null
  searchId: string = ""
  searchName: string = ""
  searchVersion: string = ""

  variableTypesList: Array<VariableTypeFilter> = [{
    name: "gauge",
    iconType: "G",
    checked: true
  }, {
    name: "class",
    iconType: "H",
    checked: true
  }, {
    name: "time_series",
    iconType: "Ts",
    checked: true
  }, {
    name: "trigger",
    iconType: "Tr",
    checked: true
  }];

  prevVariableTypesList: Array<VariableTypeFilter> = this.variableTypesList;

  variableStatesList: Array<VariableStateFilter> = [{
    name: "Created",
    checked: true
  }, {
    name: "Test",
    checked: true
  }, {
    name: "Published",
    checked: true
  }, {
    name: "Deprecated",
    checked: true
  }];

  prevVariableStatesList: Array<VariableStateFilter> = this.variableStatesList;

  selectedFilterVersion: string = "latest";

  prevSelectedFilterVersion: string = this.selectedFilterVersion;

  isLoadingVariableList: boolean = false
  toastData: ToastData | null = { message: "Test Message", variant: "error" };
  rootStore: RootStoreType | null = null;

  constructor(root: RootStoreType) {
    this.rootStore = root;
    return makeObservable(this, {
      variableList: observable,
      filteredVariableList: observable,
      selectedVariable: observable,
      searchId: observable,
      searchName: observable,
      searchVersion: observable,
      variableTypesList: observable,
      variableStatesList: observable,
      selectedFilterVersion: observable,
      toastData: observable,
      loadVariables: action,
      selectVariableForViewing: action,
      clearSelectVariable: action,
      setSearchId: action,
      setSearchName: action,
      setSearchVersion: action,
      filter: action,
      onChangeVariableTypeFilter: action,
      onChangeVariableStateFilter: action,
      onChangeVersionFilter: action,
      onResetFilters: action,
      onSubmitFilters: action,
      onCancelFilters: action,
      sortedFilteredTableData: computed,
    });
  }

  loadVariables = () => {
    if (!this.isLoadingVariableList) {
      this.isLoadingVariableList = true;
      this.toastData = null;

      getVariableList().then((response) => {
        this.variableList = response.data;
        this.onSubmitFilters()

        this.isLoadingVariableList = false;
        //after save, we say saved successfully
        //this.rootStore?.toastStore?.addToast("Loading Successfull!","success");
      }).catch(error => {
        this.toastData = { message: error.message, variant: 'error' };
        this.rootStore?.toastStore?.addToast(error.message, "error");
      }).finally(() => {
        this.isLoadingVariableList = false;
      })
    }
  }

  selectVariableForViewing = (varaible: VariableType) => {
    this.selectedVariable = varaible;
  }

  clearSelectVariable = () => {
    this.selectedVariable = null;
  }

  setSearchId = (value: string) => {
    this.searchId = value;
  }

  setSearchName = (value: string) => {
    this.searchName = value;
  }

  setSearchVersion = (value: string) => {
    this.searchVersion = value;
  }

  filter = (varaible: VariableType) => {
    this.selectedVariable = varaible;
  }

  onChangeVariableTypeFilter = (typeName: string | undefined, checked: boolean) => {
    this.variableTypesList = this.variableTypesList.map((item) => item.name === typeName ? { ...item, checked: checked } : item)
  }

  onChangeVariableStateFilter = (typeName: string | undefined, checked: boolean) => {
    this.variableStatesList = this.variableStatesList.map((item) => item.name === typeName ? { ...item, checked: checked } : item)
  }

  onChangeVersionFilter = (filterValue: string) => {
    this.selectedFilterVersion = filterValue
  }

  onCancelFilters = () => {
    this.variableTypesList = this.prevVariableTypesList
    this.variableStatesList = this.prevVariableStatesList
    this.selectedFilterVersion = this.prevSelectedFilterVersion
    this.onSubmitFilters()
  }

  onResetFilters = () => {
    this.variableTypesList = this.prevVariableTypesList = this.variableTypesList.map((item) => { item.checked = true; return item; });
    this.variableStatesList = this.prevVariableStatesList = this.variableStatesList.map((item) => { item.checked = true; return item; });
    this.selectedFilterVersion = this.prevSelectedFilterVersion = "latest";
    this.onSubmitFilters()
  }

  onSubmitFilters = () => {
    if (this.variableList.length === 0) {
      return []
    }
    // uncomment the lines once the API return correct type property for the variale list
    const selectedTypes = this.variableTypesList.filter(x => x.checked).map(x => x.name);
    let filteredData = this.variableList.filter((x) => {
      //return x.type == null
      return selectedTypes.indexOf(x.type) > -1 || !x.hasOwnProperty('type');
    });

    //TODO check this function to allow same name and different versions to go thru. 
    const selectedStatusTypes = this.variableStatesList.filter(x => x.checked).map(x => x.name);
    filteredData = filteredData.filter((x) => {
      return selectedStatusTypes.indexOf(x.status) > -1
    });

    if (this.selectedFilterVersion === "latest") {
      filteredData = this.sortVariablesWithHighestValue(filteredData)
    }

    this.filteredVariableList = filteredData;
    this.prevSelectedFilterVersion = this.selectedFilterVersion;
    this.prevVariableStatesList = this.variableStatesList;
    this.prevVariableTypesList = this.variableTypesList;
  }

  sortVariablesWithHighestValue = (variables: Array<VariableType>): Array<VariableType> => Object.values(
    variables.reduce((acc, variable) => {
      const { name } = variable;
      let existingVariable = acc[name];
      if (!existingVariable || compareVersions(variable.version, existingVariable.version) > 0) {
        acc[name] = variable;
      }
      return acc;
    }, {} as Record<string, VariableType>)
  ).sort((a, b) => compareVersions(b.version, a.version));

  get sortedFilteredTableData() {
    var ids: Array<VariableType> = orderBy(this.filteredVariableList, ["id"], ["asc"]).filter(
      (obj: any) => {
        let showId = this.searchId.length > 0 ? obj?.id?.toString().indexOf(this.searchId) !== -1 : true;
        return showId;
      }
    );

    var variableNames: Array<VariableType> = orderBy(this.filteredVariableList, ["name"], ["asc"]).filter((obj: any) => {
      let showVariable = this.searchName.length > 0 ? obj?.name?.toLowerCase().indexOf(this.searchName.toLowerCase()) !== -1 : true;
      return showVariable;
    });

    var versions: Array<VariableType> = orderBy(this.filteredVariableList, ["version"], ["asc"]).filter((obj: any) => {
      let showVersion = this.searchVersion.length > 0 ? obj?.version?.toLowerCase().indexOf(this.searchVersion.toLowerCase()) !== -1 : true;
      return showVersion;
    });

    return intersection(ids, variableNames, versions);
  };
  

}
